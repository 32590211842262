// Toggle.js
import React, { useContext } from 'react';
import './Toggle.css'; // Import the brutalist CSS file
import { ThemeContext } from '../../ThemeContext'; // Import the ThemeContext

const Toggle = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <label className="toggle-wrapper">
      <input
        className="brutalist-toggle-input"
        type="checkbox"
        checked={theme === 'dark'}
        onChange={toggleTheme}
      />
      <span className="brutalist-toggle-slider"></span>
    </label>
  );
};

export default Toggle;
