import React from 'react';
import LanguageCourse from '../../Components/LanguageCourse/LanguageCourse';
import { useEnglishCourse } from '../../data/englishLessons';

const EnglishLessons = () => {
    const { englishLessons, englishTrainers, englishCourseStats, courseName } = useEnglishCourse();

    return (
        <LanguageCourse
            countryCode="GB"
            languageName={courseName}
            lessons={englishLessons}
            courseStats={englishCourseStats}
            trainers={englishTrainers}
        />
    );
};

export default EnglishLessons;
