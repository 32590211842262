import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';
import './Lessons.css';
import { useLanguage } from '../LanguageContext'; // Use the LanguageContext

const Paths = () => {
  const navigate = useNavigate();
  const { language } = useLanguage(); // Get the current language from context

  // Text for each part of the component depending on the selected language
  const texts = {
    selectLanguage: language === 'English' ? 'Select a language to learn:'
      : language === 'Deutsch' ? 'Wähle eine Sprache zum Lernen aus:'
        : language === 'Español' ? 'Selecciona un idioma para aprender:'
          : language === 'Français' ? 'Sélectionnez une langue à apprendre :'
            : language === 'Italiano' ? 'Seleziona una lingua da imparare:'
              : language === 'Português' ? 'Selecione um idioma para aprender:'
                : 'Выберите язык для изучения:',
    languages: {
      english: language === 'English' ? 'English'
        : language === 'Deutsch' ? 'Englisch'
          : language === 'Español' ? 'Inglés'
            : language === 'Français' ? 'Anglais'
              : language === 'Italiano' ? 'Inglese'
                : language === 'Português' ? 'Inglês'
                  : 'Английский',
      german: language === 'English' ? 'German'
        : language === 'Deutsch' ? 'Deutsch'
          : language === 'Español' ? 'Alemán'
            : language === 'Français' ? 'Allemand'
              : language === 'Italiano' ? 'Tedesco'
                : language === 'Português' ? 'Alemão'
                  : 'Немецкий',
      russian: language === 'English' ? 'Russian'
        : language === 'Deutsch' ? 'Russisch'
          : language === 'Español' ? 'Ruso'
            : language === 'Français' ? 'Russe'
              : language === 'Italiano' ? 'Russo'
                : language === 'Português' ? 'Russo'
                  : 'Русский',
      norwegian: language === 'English' ? 'Norwegian'
        : language === 'Deutsch' ? 'Norwegisch'
          : language === 'Español' ? 'Noruego'
            : language === 'Français' ? 'Norvégien'
              : language === 'Italiano' ? 'Norvegese'
                : language === 'Português' ? 'Norueguês'
                  : 'Норвежский',
    }
  };

  return (
    <div className="paths-container">
      <h2>{texts.selectLanguage}</h2> {/* Display the title based on the current language */}
      <div className="language-grid">
        <div className="language-widget" onClick={() => navigate('/lessons/english')}>
          <ReactCountryFlag countryCode="GB" svg style={flagStyles} />
          <h3>{texts.languages.english}</h3> {/* Display 'English' in the selected language */}
        </div>
        <div className="language-widget" onClick={() => navigate('/lessons/german')}>
          <ReactCountryFlag countryCode="DE" svg style={flagStyles} />
          <h3>{texts.languages.german}</h3> {/* Display 'German' in the selected language */}
        </div>
        <div className="language-widget" onClick={() => navigate('/lessons/russian')}>
          <ReactCountryFlag countryCode="RU" svg style={flagStyles} />
          <h3>{texts.languages.russian}</h3> {/* Display 'Russian' in the selected language */}
        </div>
        <div className="language-widget" onClick={() => navigate('/lessons/norwegian')}>
          <ReactCountryFlag countryCode="NO" svg style={flagStyles} />
          <h3>{texts.languages.norwegian}</h3> {/* Display 'Norwegian' in the selected language */}
        </div>
      </div>
    </div>
  );
};

// Flag styles as before
const flagStyles = {
  width: '80px',
  height: '60px',
  marginBottom: '10px',
  borderRadius: '8px',
  boxShadow: '4px 4px 0px var(--signature-shadow-color)',
  border: '1px solid var(--signature-border-color)',
};

export default Paths;
