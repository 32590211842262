import React from 'react';
import { useLanguage } from '../../LanguageContext'; // Import the Language Context
import './LanguageSelector.css'; // Import the CSS file

const LanguageSelector = () => {
  const { language, setLanguage } = useLanguage(); // Use the context
  const languages = ['English', 'Deutsch', 'Español', 'Français', 'Italiano', 'Português', 'Русский'];

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value); // Update the selected language
    event.target.blur(); // Force focus out after selecting to close the dropdown immediately
  };

  return (
    <select
      className="language-selector-dropdown"
      value={language}
      onChange={handleLanguageChange}
    >
      {languages.map((lang, index) => (
        <option key={index} value={lang}>
          {lang}
        </option>
      ))}
    </select>
  );
};

export default LanguageSelector;
