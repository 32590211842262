import React from 'react';
import './ReducedNavbar.css'; // Import the CSS file for styling
import Toggle from './Toggle'; // Import the Toggle component
import ProfileButton from './ProfileButton'; // Import the ProfileButton component
import LanguageSelector from './LanguageSelector'; // Import the LanguageSelector component

const ReducedNavbar = () => {
  return (
    <div className="reduced-navbar">
      <LanguageSelector />
      <Toggle />
      <ProfileButton />
    </div>
  );
};

export default ReducedNavbar;