import React from 'react';
import './LanguageCourse.css';
import ReducedNavbar from '../UI/ReducedNavbar';
import Searchbar from '../UI/Searchbar';
import { BookOutlined, QuizOutlined, CheckBox, CheckBoxOutlineBlank, LockOutlined } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';
import { useLanguage } from '../../LanguageContext';

// Move common texts here
const getCommonTexts = (language) => ({
    lesson: language === 'English' ? 'Lesson'
        : language === 'Deutsch' ? 'Lektion'
            : language === 'Español' ? 'Lección'
                : language === 'Français' ? 'Leçon'
                    : language === 'Italiano' ? 'Lezione'
                        : language === 'Português' ? 'Lição'
                            : 'Урок',
    test: language === 'English' ? 'Test'
        : language === 'Deutsch' ? 'Test'
            : language === 'Español' ? 'Prueba'
                : language === 'Français' ? 'Test'
                    : language === 'Italiano' ? 'Test'
                        : language === 'Português' ? 'Teste'
                            : 'Тест',
    courseProgress: language === 'English' ? 'Course Progress'
        : language === 'Deutsch' ? 'Kursfortschritt'
            : language === 'Español' ? 'Progreso del Curso'
                : language === 'Français' ? 'Progrès du Cours'
                    : language === 'Italiano' ? 'Progresso del Corso'
                        : language === 'Português' ? 'Progresso do Curso'
                            : 'Прогресс курса',
    lessonsCompleted: language === 'English' ? 'Lessons Completed'
        : language === 'Deutsch' ? 'Abgeschlossene Lektionen'
            : language === 'Español' ? 'Lecciones Completadas'
                : language === 'Français' ? 'Leçons Terminées'
                    : language === 'Italiano' ? 'Lezioni Completate'
                        : language === 'Português' ? 'Lições Concluídas'
                            : 'Пройдено уроков',
    testsCompleted: language === 'English' ? 'Tests Completed'
        : language === 'Deutsch' ? 'Abgeschlossene Tests'
            : language === 'Español' ? 'Pruebas Completadas'
                : language === 'Français' ? 'Tests Terminés'
                    : language === 'Italiano' ? 'Test Completati'
                        : language === 'Português' ? 'Testes Concluídos'
                            : 'Пройдено тестов',
    wordsLearned: language === 'English' ? 'Words Learned'
        : language === 'Deutsch' ? 'Gelernte Wörter'
            : language === 'Español' ? 'Palabras Aprendidas'
                : language === 'Français' ? 'Mots Appris'
                    : language === 'Italiano' ? 'Parole Imparate'
                        : language === 'Português' ? 'Palavras Aprendidas'
                            : 'Выучено слов',
    currentLevel: language === 'English' ? 'Current Level'
        : language === 'Deutsch' ? 'Aktuelles Niveau'
            : language === 'Español' ? 'Nivel Actual'
                : language === 'Français' ? 'Niveau Actuel'
                    : language === 'Italiano' ? 'Livello Attuale'
                        : language === 'Português' ? 'Nível Atual'
                            : 'Текущий уровень',
    course: language === 'English' ? 'Course'
        : language === 'Deutsch' ? 'Kurs'
            : language === 'Español' ? 'Curso'
                : language === 'Français' ? 'Cours'
                    : language === 'Italiano' ? 'Corso'
                        : language === 'Português' ? 'Curso'
                            : 'Курс',
    german: language === 'English' ? 'German'
        : language === 'Deutsch' ? 'Deutsch'
            : language === 'Español' ? 'Alemán'
                : language === 'Français' ? 'Allemand'
                    : language === 'Italiano' ? 'Tedesco'
                        : language === 'Português' ? 'Alemão'
                            : 'Немецкий',
});

// Helper function to check if item should be locked
const isItemLocked = (currentIndex, lessons) => {
    for (let i = 0; i < currentIndex; i++) {
        if (lessons[i].type === 'test' && lessons[i].progress < 100) {
            return {
                locked: true,
                testNumber: lessons[i].classifier.split(' ')[1]
            };
        }
    }
    return { locked: false };
};

const LanguageCourse = ({ countryCode, languageName, lessons = [], courseStats = {}, trainers = [] }) => {
    const navigate = useNavigate();
    const { language } = useLanguage();
    const texts = getCommonTexts(language);

    const safeStats = {
        completedLessons: 0,
        totalLessons: 0,
        completedTests: 0,
        totalTests: 0,
        wordsLearned: 0,
        currentLevel: '',
        ...courseStats
    };

    const handleLessonClick = (path, locked, testNumber) => {
        if (locked) {
            alert(`Please complete Test ${testNumber} before you continue!`);
            return;
        }
        navigate(path);
    };

    return (
        <div className="language-course">
            <ReducedNavbar />
            <div className="content">
                <div className="language-header">
                    <ReactCountryFlag
                        countryCode={countryCode}
                        svg
                        style={{
                            width: '40px',
                            height: '30px',
                            borderRadius: '4px',
                            boxShadow: '2px 2px 0px var(--shadow-color)',
                            border: '1px solid var(--border-color)',
                        }}
                    />
                    <h2>{languageName} {texts.course}</h2>
                </div>
                <div className="sidebar">
                    <Searchbar />
                    <div className="stats-container">
                        <div className="stats-title">{texts.courseProgress}</div>
                        <div className="stats-row">
                            <span>{texts.lessonsCompleted}:</span>
                            <span>
                                <span className="completed-value">{safeStats.completedLessons}</span>
                                <span className="total-count">/{safeStats.totalLessons}</span>
                            </span>
                        </div>
                        <div className="stats-row">
                            <span>{texts.testsCompleted}:</span>
                            <span>
                                <span className="completed-value">{safeStats.completedTests}</span>
                                <span className="total-count">/{safeStats.totalTests}</span>
                            </span>
                        </div>
                        <div className="stats-divider" />
                        <div className="stats-row">
                            <span>{texts.wordsLearned}:</span>
                            <span className="completed-value">{safeStats.wordsLearned}</span>
                        </div>
                        <div className="level-indicator">
                            <span>{texts.currentLevel}:</span>
                            <span>{safeStats.currentLevel}</span>
                        </div>
                    </div>
                    <div className="trainer-buttons">
                        {trainers.map((trainer, index) => (
                            <button
                                key={index}
                                className="trainer-button"
                                onClick={() => navigate(trainer.path)}
                            >
                                {trainer.icon}
                                {trainer.title}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="lessons-container">
                    {lessons.map((lesson, index) => {
                        const { locked, testNumber } = isItemLocked(index, lessons);
                        const cardClassName = `lesson-card ${lesson.type === 'test' ? 'test-card' : ''} ${locked ? 'locked-card' : ''}`;

                        return (
                            <div
                                key={lesson.id}
                                className={cardClassName}
                                onClick={() => handleLessonClick(lesson.path, locked, testNumber)}
                            >
                                {locked ? <LockOutlined className="lesson-icon" /> : lesson.icon}
                                <div className="lesson-info">
                                    <span className="classifier">{lesson.classifier}</span>
                                    <span className="title">{lesson.title}</span>
                                    <span className="duration">{lesson.duration}</span>
                                    <div className="progress-section">
                                        <LinearProgress
                                            variant="determinate"
                                            value={lesson.progress}
                                            className={`progress-bar ${lesson.type === 'test' ? 'test-progress' : ''}`}
                                        />
                                        <span className="progress-text">{lesson.progress}%</span>
                                    </div>
                                    {lesson.progress === 100 ?
                                        <CheckBox className="checkbox-icon checkbox-checked" /> :
                                        <CheckBoxOutlineBlank className="checkbox-icon" />
                                    }
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

// Export both the component and the common texts
export { getCommonTexts };
export default LanguageCourse;
