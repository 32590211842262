import React, { useState } from 'react';

const StoryConverter = () => {
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState('');

    const convertText = () => {
        const regex = /([.,!?;:"'])|(\s+)|([^.,!?;:"'\s]+)/g;
        const matches = inputText.match(regex);

        if (!matches) return;

        // Filter out pure whitespace entries
        const tokens = matches.filter(token => token.trim() !== '');

        // Convert each token into the desired format
        const converted = tokens.map(token => {
            // Check if token is punctuation
            if (/^[.,!?;:"']$/.test(token)) {
                return `{ isPunctuation: true, text: '${token}' }`;
            }
            // Otherwise it's a word
            return `{ text: '${token}', translations: { English: ' ', Deutsch: ' ', Русский: ' ' } }`;
        });

        // Join the array with commas and newlines
        const formattedOutput = converted.join(',\n');
        setOutputText(formattedOutput);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(outputText);
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Story Text Converter</h2>
            <div style={styles.inputContainer}>
                <textarea
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    placeholder="Enter your text here..."
                    style={styles.textarea}
                />
                <div style={styles.buttonContainer}>
                    <button onClick={convertText} style={styles.button} className="converter-button">
                        Convert
                    </button>
                </div>
            </div>
            {outputText && (
                <div style={styles.outputContainer}>
                    <div style={styles.buttonContainer}>
                        <button onClick={handleCopy} style={styles.button} className="converter-button">
                            Copy to Clipboard
                        </button>
                    </div>
                    <pre style={styles.output}>{outputText}</pre>
                </div>
            )}
            <style>
                {`
                    .converter-button {
                        border: 1px solid var(--border-color);
                        background-color: var(--signature-color);
                        color: var(--text-color);
                        box-shadow: 2px 2px 0px var(--shadow-border), 4px 4px var(--shadow-color);
                        transition: all 0.05s;
                    }

                    .converter-button:hover {
                        transform: translate(-1px, -1px);
                        box-shadow: 6px 6px var(--shadow-color);
                    }

                    .converter-button:active {
                        transform: translate(2px, 2px);
                        box-shadow: none;
                    }
                `}
            </style>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        maxWidth: '800px',
        margin: '0 auto',
        fontFamily: '"Courier New", monospace',
    },
    title: {
        textAlign: 'center',
        color: 'var(--text-color)',
        fontSize: '28px',
        fontWeight: '600',
    },
    inputContainer: {
        marginBottom: '20px',
    },
    textarea: {
        width: '100%',
        height: '150px',
        marginBottom: '10px',
        padding: '10px',
        fontFamily: '"Courier New", monospace',
        backgroundColor: 'var(--button-bg-color)',
        border: '1px solid var(--border-color)',
        color: 'var(--text-color)',
        borderRadius: '8px',
    },
    outputContainer: {
        backgroundColor: 'var(--button-bg-color)',
        padding: '20px',
        borderRadius: '8px',
        border: '1px solid var(--border-color)',
    },
    output: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
        marginTop: '10px',
        fontFamily: '"Courier New", monospace',
        color: 'var(--text-color)',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        marginBottom: '10px',
    },
    button: {
        padding: '10px 20px',
        borderRadius: '8px',
        cursor: 'pointer',
        fontFamily: '"Courier New", monospace',
        fontWeight: 'bold',
        minWidth: '90px',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export default StoryConverter;
