import React, { useState, useEffect, useRef } from 'react';
import './InfinyVocab.css'; // Import the CSS file
import Keyboard from '../Components/Infiny/Keyboard'; // Import the Keyboard component
import SendIcon from '@mui/icons-material/Send'; // Import the MUI Send icon
import ReducedNavbar from '../Components/UI/ReducedNavbar'; // Import the ReducedNavbar component
import { useLanguage } from '../LanguageContext'; // Use the Language Context
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import BuildIcon from '@mui/icons-material/Build';
import { useNavigate } from 'react-router-dom';

// Import vocabulary sets
import vocabulary from '../data/vocabulary.json';

// Add this mapping object at the top of the file, after the imports
const languageNativeNames = {
  'albanian': 'Shqip',
  'arabic': 'العربية',
  'armenian': 'Հայերեն',
  'basque': 'Euskara',
  'belarusian': 'Беларуская',
  'bengali': 'বাংলা',
  'bosnian': 'Bosanski',
  'bulgarian': 'Български',
  'catalan': 'Català',
  'chichewa': 'Chichewa',
  'croatian': 'Hrvatski',
  'czech': 'Čeština',
  'danish': 'Dansk',
  'dutch': 'Nederlands',
  'german': 'Deutsch',
  'english': 'English',
  'estonian': 'Eesti',
  'faroese': 'Føroyskt',
  'finnish': 'Suomi',
  'french': 'Français',
  'galician': 'Galego',
  'georgian': 'ქართული',
  'greek': 'Ελληνικά',
  'hausa': 'Hausa',
  'hindi': 'हिन्दी',
  'hungarian': 'Magyar',
  'icelandic': 'Íslenska',
  'igbo': 'Igbo',
  'indonesian': 'Bahasa Indonesia',
  'irish': 'Gaeilge',
  'italian': 'Italiano',
  'kinyarwanda': 'Kinyarwanda',
  'kirundi': 'Kirundi',
  'korean': '한국어',
  'kurdish': 'Kurdî',
  'latvian': 'Latviešu',
  'lithuanian': 'Lietuvių',
  'luxembourgish': 'Lëtzebuergesch',
  'malay': 'Bahasa Melayu',
  'montenegrin': 'Crnogorski',
  'norwegian': 'Norsk',
  'polish': 'Polski',
  'portuguese': 'Português',
  'punjabi': 'ਪੰਜਾਬੀ',
  'romanian': 'Română',
  'russian': 'Русский',
  'sami': 'Sámegiella',
  'serbian': 'Српски',
  'sesotho': 'Sesotho',
  'shona': 'Shona',
  'slovak': 'Slovenčina',
  'slovenian': 'Slovenščina',
  'spanish': 'Español',
  'swahili': 'Kiswahili',
  'swedish': 'Svenska',
  'turkish': 'Türkçe',
  'ukrainian': 'Українська',
  'urdu': 'اردو',
  'vietnamese': 'Tiếng Việt',
  'yoruba': 'Yorùbá',
  'japanese': '日本語',
  'chinese': '中文'
};

// Add this new component above the InfinyVocab function
const CustomOption = ({ value, icon, label }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      {icon}
      <span>{label}</span>
    </div>
  );
};

function InfinyVocab() {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [userInput, setUserInput] = useState('');
  const [feedback, setFeedback] = useState('');
  const [languageToLearn, setLanguageToLearn] = useState('german'); // Default to German
  const [keyboardLayout, setKeyboardLayout] = useState('german'); // Keep track of the keyboard layout
  const [nativeLanguage, setNativeLanguage] = useState('english'); // Make native language stateful
  const [vocabularySet, setVocabularySet] = useState('default'); // New state for vocabulary set
  const [score, setScore] = useState(0); // New state for score
  const [customVocabulary, setCustomVocabulary] = useState(null); // New state for custom vocabulary
  const [customVocabularyTitle, setCustomVocabularyTitle] = useState(''); // New state for custom vocabulary title
  const [availableLanguages, setAvailableLanguages] = useState(Object.keys(languageNativeNames)); // Languages dropdown options
  const [probabilitySet, setProbabilitySet] = useState([]); // Probability array for words
  const [selectedWord, setSelectedWord] = useState(null); // To store the currently selected word
  const fileInputRef = useRef(null); // Create a ref for the file input
  const navigate = useNavigate();

  // Use the Language Context
  const { language } = useLanguage();

  // Function to handle file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const jsonData = JSON.parse(e.target.result);

          // Extract languages dynamically based on the keys in the "data" array
          const firstDataEntry = jsonData.data[0];
          const detectedLanguages = Object.keys(firstDataEntry);

          // Set available languages based on JSON content
          setAvailableLanguages(detectedLanguages);

          // Set default languages to the first two in the detected languages
          setNativeLanguage(detectedLanguages[0]);
          setLanguageToLearn(detectedLanguages[1]);

          // Check if the JSON has the correct format
          if (jsonData.data && Array.isArray(jsonData.data) && jsonData.title) {
            setCustomVocabulary(jsonData.data);
            setCustomVocabularyTitle(jsonData.title);
            setVocabularySet('custom');
            setCurrentWordIndex(0);
          } else {
            throw new Error('Invalid format');
          }
        } catch (error) {
          alert('Error: ' + error.message);
        }
      };
      reader.readAsText(file);
    }
  };

  // Function to handle file selection
  const handleFileSelection = (event) => {
    handleFileUpload(event); // Call the existing file upload handler
  };

  // Determine the current vocabulary based on the selected set
  const currentVocabulary = vocabularySet === 'custom'
    ? (customVocabulary || []) // Use custom vocabulary or empty array
    : vocabulary.data; // Default vocabulary

  // Initialize probability array
  useEffect(() => {
    if (currentVocabulary.length > 0) {
      const initialProbabilities = currentVocabulary.map((word) => ({
        word: word,
        probability: 1 / currentVocabulary.length
      }));
      setProbabilitySet(initialProbabilities);
    }
  }, [vocabularySet, customVocabulary]);

  // Normalize probabilities to sum to 1
  const normalizeProbabilities = (probSet) => {
    const total = probSet.reduce((sum, { probability }) => sum + probability, 0);
    return probSet.map(({ word, probability }) => ({
      word: word,
      probability: probability / total
    }));
  };

  // Adjust probabilities after correct/incorrect answers
  const adjustProbabilities = (isCorrect, currentWord) => {
    setProbabilitySet((prevProbSet) => {
      const updatedProbSet = prevProbSet.map((tuple) => {
        if (tuple.word === currentWord) {
          return {
            word: tuple.word,
            probability: isCorrect ? tuple.probability / 2 : tuple.probability * 2
          };
        }
        return tuple;
      });
      return normalizeProbabilities(updatedProbSet);
    });
  };

  // Weighted random selection of the next word
  const getNextWordIndex = () => {
    const randomValue = Math.random();
    let sum = 0;
    for (let i = 0; i < probabilitySet.length; i++) {
      sum += probabilitySet[i].probability;
      if (randomValue <= sum) {
        return i;
      }
    }
    return 0;
  };

  // Set the first word when vocabulary or probabilities change
  useEffect(() => {
    if (probabilitySet.length > 0) {
      const initialIndex = getNextWordIndex();
      setSelectedWord(probabilitySet[initialIndex].word);
    }
  }, [probabilitySet]);

  // Check if the answer is correct
  const checkAnswer = () => {
    const correctAnswer = selectedWord[languageToLearn];
    if (userInput === correctAnswer) {
      setFeedback(
        language === 'English' ? 'Correct!'
          : language === 'Deutsch' ? 'Richtig!'
            : language === 'Español' ? '¡Correcto!'
              : language === 'Français' ? 'Correct !'
                : language === 'Italiano' ? 'Corretto!'
                  : language === 'Português' ? 'Correto!'
                    : 'Правильно!'
      );
      setScore((prevScore) => prevScore + 10);
      adjustProbabilities(true, selectedWord);
    } else {
      setFeedback(
        language === 'English' ? `The correct answer was "${correctAnswer}".`
          : language === 'Deutsch' ? `Die richtige Antwort war "${correctAnswer}".`
            : language === 'Español' ? `La respuesta correcta era "${correctAnswer}".`
              : language === 'Français' ? `La bonne réponse était "${correctAnswer}".`
                : language === 'Italiano' ? `La risposta corretta era "${correctAnswer}".`
                  : language === 'Português' ? `A resposta correta era "${correctAnswer}".`
                    : `Правильный ответ был "${correctAnswer}".`
      );
      adjustProbabilities(false, selectedWord);
    }

    // Set the next word after answer submission
    const nextIndex = getNextWordIndex();
    setSelectedWord(probabilitySet[nextIndex].word);
  };

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    checkAnswer();
    setUserInput(''); // Clear input field
  };

  // Handle input changes
  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleVirtualKeyPress = (key) => {
    if (key === 'backspace') {
      setUserInput((prev) => prev.slice(0, -1)); // Remove the last character
    } else if (key === 'space') {
      setUserInput((prev) => prev + ' ');
    } else {
      setUserInput((prev) => prev + key);
    }
  };

  // Automatically update the keyboard layout when the language changes
  useEffect(() => {
    setKeyboardLayout(languageToLearn);
  }, [languageToLearn]);

  // Ensure languages are not the same
  const handleNativeLanguageChange = (e) => {
    const newNativeLanguage = e.target.value;
    setNativeLanguage(newNativeLanguage);
    if (newNativeLanguage === languageToLearn) {
      setLanguageToLearn(nativeLanguage); // Swap the languages if they are the same
    }
  };

  const handleLanguageToLearnChange = (e) => {
    const newLanguageToLearn = e.target.value;
    setLanguageToLearn(newLanguageToLearn);
    if (newLanguageToLearn === nativeLanguage) {
      setNativeLanguage(languageToLearn); // Swap the languages if they are the same
    }
  };

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const handleBrowseClick = () => {
    alert('Browsing coming soon!');
  };

  return (
    <div className="InfinyVocab">
      <ReducedNavbar /> {/* Add the ReducedNavbar component here */}

      <h1>
        {language === 'English' ? 'Vocabulary Trainer'
          : language === 'Deutsch' ? 'Vokabeltrainer'
            : language === 'Español' ? 'Entrenador de Vocabulario'
              : language === 'Français' ? 'Entraîneur de Vocabulaire'
                : language === 'Italiano' ? 'Allenatore di Vocabolario'
                  : language === 'Português' ? 'Treinador de Vocabulário'
                    : 'Тренажер словаря'}
      </h1>

      <div className="word-display">
        <p>
          <strong>
            {language === 'English' ? 'Translate'
              : language === 'Deutsch' ? 'Übersetze'
                : language === 'Español' ? 'Traduce'
                  : language === 'Français' ? 'Traduisez'
                    : language === 'Italiano' ? 'Traduci'
                      : language === 'Português' ? 'Traduza'
                        : 'Перевести'}
          </strong>

          {/* Vocabulary Set Selection */}
          <div className="select-wrapper">
            <select
              value={vocabularySet}
              onChange={(e) => {
                const value = e.target.value;
                if (value === 'custom') {
                  fileInputRef.current.click();
                } else if (value === 'create') {
                  navigate('/vocabulary/create');
                }
                setVocabularySet(value);
              }}
            >
              <option value="default">
                {language === 'English' ? 'Default Set'
                  : language === 'Deutsch' ? 'Standard-Set'
                    : language === 'Español' ? 'Conjunto Predeterminado'
                      : language === 'Français' ? 'Ensemble par Défaut'
                        : language === 'Italiano' ? 'Set Predefinito'
                          : language === 'Português' ? 'Conjunto Padrão'
                            : 'Стандартный набор'}
              </option>
              <option value="custom">
                {customVocabularyTitle || (
                  language === 'English' ? 'Add +'
                    : language === 'Deutsch' ? 'Hinzufügen +'
                      : language === 'Español' ? 'Añadir +'
                        : language === 'Français' ? 'Ajouter +'
                          : language === 'Italiano' ? 'Aggiungi +'
                            : language === 'Português' ? 'Adicionar +'
                              : 'Добавить +'
                )}
              </option>
              <option value="create">
                {language === 'English' ? 'Create ⚙'
                  : language === 'Deutsch' ? 'Erstellen ⚙'
                    : language === 'Español' ? 'Crear ⚙'
                      : language === 'Français' ? 'Créer ⚙'
                        : language === 'Italiano' ? 'Crea ⚙'
                          : language === 'Português' ? 'Criar ⚙'
                            : 'Создать ⚙'}
              </option>
            </select>
            <div className="select-icon">
              {vocabularySet === 'create' && <BuildIcon />}
              {vocabularySet === 'browse' && <SearchIcon />}
            </div>
          </div>

          <strong>
            {language === 'English' ? 'from'
              : language === 'Deutsch' ? 'von'
                : language === 'Español' ? 'de'
                  : language === 'Français' ? 'de'
                    : language === 'Italiano' ? 'da'
                      : language === 'Português' ? 'de'
                        : 'из'}
          </strong>

          {/* Dynamically populated native language selection */}
          <select
            value={nativeLanguage}
            onChange={handleNativeLanguageChange}
          >
            {availableLanguages.map((lang) => (
              <option key={lang} value={lang}>
                {languageNativeNames[lang] || capitalizeFirstLetter(lang)}
              </option>
            ))}
          </select>

          <strong>
            {language === 'English' ? 'to'
              : language === 'Deutsch' ? 'nach'
                : language === 'Español' ? 'a'
                  : language === 'Français' ? 'vers'
                    : language === 'Italiano' ? 'a'
                      : language === 'Português' ? 'para'
                        : 'на'}
          </strong>

          {/* Dynamically populated language to learn selection */}
          <select
            value={languageToLearn}
            onChange={handleLanguageToLearnChange}
          >
            {availableLanguages.map((lang) => (
              <option key={lang} value={lang}>
                {languageNativeNames[lang] || capitalizeFirstLetter(lang)}
              </option>
            ))}
          </select>
          :
        </p>
        <h2>{selectedWord ? selectedWord[nativeLanguage] : 'No word available'}</h2> {/* Display selected word */}
      </div>

      {/* Hidden file input for file selection */}
      <input
        type="file"
        accept=".json"
        onChange={handleFileSelection}
        ref={fileInputRef} // Attach the ref to the file input
        style={{ display: 'none' }} // Hide the file input
      />

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          placeholder={
            language === 'English' ? `Enter the ${capitalizeFirstLetter(languageToLearn)} word here`
              : language === 'Deutsch' ? `${capitalizeFirstLetter(languageToLearn)}es Wort hier eingeben`
                : language === 'Español' ? `Ingrese la palabra en ${languageToLearn} aquí`
                  : language === 'Français' ? `Entrez le mot en ${languageToLearn} ici`
                    : language === 'Italiano' ? `Inserisci la parola in ${languageToLearn} qui`
                      : language === 'Português' ? `Digite a palavra em ${languageToLearn} aqui`
                        : `Введите слово на ${languageToLearn} здесь`
          }
        />
        <button type="submit">
          <SendIcon className="submit-icon" /> {/* MUI Send icon */}
        </button>
      </form>

      <div className="feedback">
        <p><strong>{feedback}</strong></p>
      </div>

      <div className="score-display">{score}</div> {/* Add score display */}

      {/* Pass the keyboard layout and the function to handle key presses */}
      <Keyboard layout={keyboardLayout} onKeyPress={handleVirtualKeyPress} />
    </div>
  );
}

export default InfinyVocab;
