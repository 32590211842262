import React from 'react';
import './Home.css';
import Navbar from '../Components/UI/Navbar';
import WidgetContainer from '../Components/UI/WidgetContainer';

const Home = () => {
  return (
    <div className="home-container">
      <Navbar />
      <WidgetContainer />
    </div>

  );
};

export default Home;
