import React, { useState } from 'react';
import './Searchbar.css'; // Import the CSS file for styling
import { useLanguage } from '../../LanguageContext'; // Use the Language Context

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { language } = useLanguage(); // Get the current language from the LanguageContext

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Placeholder text based on the selected language
  const placeholderText =
    language === 'English'
      ? 'Search the page...'
      : language === 'Deutsch'
        ? 'Seite durchsuchen...'
        : language === 'Español'
          ? 'Buscar en la página...'
          : language === 'Français'
            ? 'Rechercher dans la page...'
            : language === 'Italiano'
              ? 'Cerca nella pagina...'
              : language === 'Português'
                ? 'Pesquisar na página...'
                : 'Поиск по странице...';

  return (
    <div className="input-container">
      <input
        type="text"
        className="input"
        placeholder={placeholderText} // Dynamic placeholder text
        value={searchTerm}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default SearchBar;
