import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from '../../ThemeContext';
import './Keyboard.css';

// Existing layouts
const englishLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['Space']
];

const germanLayout = [
  ['q', 'w', 'e', 'r', 't', 'z', 'u', 'i', 'o', 'p', 'ü'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ö', 'ä'],
  ['Shift', 'y', 'x', 'c', 'v', 'b', 'n', 'm', 'ß'],
  ['Space']
];

const russianLayout = [
  ['й', 'ц', 'у', 'к', 'е', 'н', 'г', 'ш', 'щ', 'з', 'х', 'ъ'],
  ['ф', 'ы', 'в', 'а', 'п', 'р', 'о', 'л', 'д', 'ж', 'э'],
  ['Shift', 'я', 'ч', 'с', 'м', 'и', 'т', 'ь', 'б', 'ю'],
  ['Space']
];

// Add Norwegian layout
const norwegianLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'å'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ø'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'æ'],
  ['Space']
];

// Add Danish layout
const danishLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'å'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'æ', 'ø'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['Space']
];

// Add Swedish layout
const swedishLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'å'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ö', 'ä'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['Space']
];

// Add Finnish layout
const finnishLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'å'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ö', 'ä'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['Space']
];

// Add Icelandic layout
const icelandicLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'ð'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'æ', 'ö'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'þ', 'ý'],
  ['Space']
];

// Add French layout
const frenchLayout = [
  ['a', 'z', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['q', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'ù'],
  ['Shift', 'w', 'x', 'c', 'v', 'b', 'n', 'é', 'è', 'à'],
  ['Space']
];

// Add Spanish layout
const spanishLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'ñ'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'á', 'é'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'í', 'ó', 'ú'],
  ['Space']
];

// Add Italian layout
const italianLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'è'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ò', 'à'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'ì', 'ù'],
  ['Space']
];

// Add Portuguese layout
const portugueseLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'á'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ç', 'ã'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'õ', 'é', 'ê'],
  ['Space']
];

// Add Ukrainian layout
const ukrainianLayout = [
  ['й', 'ц', 'у', 'к', 'е', 'н', 'г', 'ш', 'щ', 'з', 'х', 'ї'],
  ['ф', 'і', 'в', 'а', 'п', 'р', 'о', 'л', 'д', 'ж', 'є'],
  ['Shift', 'я', 'ч', 'с', 'м', 'и', 'т', 'ь', 'б', 'ю'],
  ['Space']
];

// Add Lithuanian layout
const lithuanianLayout = [
  ['ą', 'ž', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ų', 'ė'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'č', 'š'],
  ['Space']
];

// Add Latvian layout
const latvianLayout = [
  ['ē', 'ū', 'ī', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ā', 'š'],
  ['Shift', 'z', 'č', 'c', 'v', 'b', 'n', 'm', 'ņ', 'ž'],
  ['Space']
];

// Add Estonian layout
const estonianLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'ü', 'õ'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ö', 'ä'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['Space']
];

// Add Polish layout
const polishLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'ż'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ł', 'ą'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'ę', 'ć', 'ń'],
  ['Space']
];

const irishLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'á', 'é', 'í', 'ó', 'ú'],
  ['Space']
];

const faroeseLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'ð'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'æ', 'ø', 'á'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'í', 'ý'],
  ['Space']
];

const belarusianLayout = [
  ['й', 'ц', 'у', 'к', 'е', 'н', 'г', 'ш', 'ў', 'з', 'х'],
  ['ф', 'ы', 'в', 'а', 'п', 'р', 'о', 'л', 'д', 'ж', 'э'],
  ['Shift', 'я', 'ч', 'с', 'м', 'і', 'т', 'ь', 'б', 'ю'],
  ['Space']
];

const czechLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ů'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'ě', 'š', 'č', 'ř', 'ž', 'ý', 'á', 'í', 'é'],
  ['Space']
];

const slovakLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ľ', 'š', 'č', 'ť', 'ž'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'ň', 'á', 'í', 'é', 'ý', 'ó', 'ú', 'ä'],
  ['Space']
];

const hungarianLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'ő', 'ú'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'é', 'á', 'ű'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'í', 'ó', 'ü', 'ö'],
  ['Space']
];

const romanianLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'ă'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ș', 'ț', 'â'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'î'],
  ['Space']
];

const slovenianLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'š'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'č', 'ć'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'ž', 'đ'],
  ['Space']
];

const basqueLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ñ'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['Space']
];

const galicianLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ñ'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'á', 'é', 'í', 'ó', 'ú'],
  ['Space']
];

const bulgarianLayout = [
  ['я', 'в', 'е', 'р', 'т', 'ъ', 'у', 'и', 'о', 'п', 'ш', 'щ'],
  ['а', 'с', 'д', 'ф', 'г', 'х', 'й', 'к', 'л', 'ю'],
  ['Shift', 'з', 'ь', 'ц', 'ж', 'б', 'н', 'м', 'ч'],
  ['Space']
];

const albanianLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ë'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'ç'],
  ['Space']
];

const greekLayout = [
  ['ς', 'ε', 'ρ', 'τ', 'υ', 'θ', 'ι', 'ο', 'π'],
  ['α', 'σ', 'δ', 'φ', 'γ', 'η', 'ξ', 'κ', 'λ'],
  ['Shift', 'ζ', 'χ', 'ψ', 'ω', 'β', 'ν', 'μ'],
  ['Space']
];

const turkishLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'ı', 'o', 'p', 'ğ', 'ü'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ş', 'i'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'ö', 'ç'],
  ['Space']
];

const samiLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'å', 'ŋ'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ö', 'ä', 'đ'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'ž', 'š', 'č'],
  ['Space']
];

const georgianLayout = [
  ['ქ', 'წ', 'ე', 'რ', 'ტ', 'ყ', 'უ', 'ი', 'ო', 'პ'],
  ['ა', 'ს', 'დ', 'ფ', 'გ', 'ჰ', 'ჯ', 'კ', 'ლ'],
  ['Shift', 'ზ', 'ხ', 'ც', 'ვ', 'ბ', 'ნ', 'მ', 'ჭ', 'ღ', 'თ', 'შ', 'ჟ'],
  ['Space']
];

// Shared layout for Croatian, Serbian, Bosnian, and Montenegrin
const southSlavicLayout = [
  ['q', 'w', 'e', 'r', 't', 'z', 'u', 'i', 'o', 'p', 'š', 'đ'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'č', 'ć', 'ž'],
  ['Shift', 'y', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['Space']
];

const armenianLayout = [
  ['ք', 'ո', 'ե', 'ռ', 'տ', 'յ', 'ւ', 'ի', 'ո', 'պ'],
  ['ա', 'ս', 'դ', 'ֆ', 'գ', 'հ', 'ջ', 'կ', 'լ'],
  ['Shift', 'զ', 'խ', 'ծ', 'վ', 'բ', 'ն', 'մ', 'շ', 'ղ'],
  ['Space']
];

const catalanLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ç'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'à', 'è', 'é', 'í', 'ï', 'ó', 'ú', 'ü'],
  ['Space']
];

const luxembourgishLayout = [
  ['q', 'w', 'e', 'r', 't', 'z', 'u', 'i', 'o', 'p', 'ä'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'é', 'ë'],
  ['Shift', 'y', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['Space']
];

const kurdishLatinLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ê', 'î', 'û'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'ç', 'ş'],
  ['Space']
];

const hindiLayout = [
  ['ौ', 'ै', 'ा', 'ी', 'ू', 'ब', 'ह', 'ग', 'द', 'ज', 'ड', 'ॉ'],
  ['ो', 'े', '्', 'ि', 'ु', 'प', 'र', 'क', 'त', 'च', 'ट'],
  ['Shift', 'ॆ', 'ं', 'म', 'न', 'व', 'ल', 'स', 'य'],
  ['Space']
];

const koreanLayout = [
  ['ㅂ', 'ㅈ', 'ㄷ', 'ㄱ', 'ㅅ', 'ㅛ', 'ㅕ', 'ㅑ', 'ㅐ', 'ㅔ'],
  ['ㅁ', 'ㄴ', 'ㅇ', 'ㄹ', 'ㅎ', 'ㅗ', 'ㅓ', 'ㅏ', 'ㅣ'],
  ['Shift', 'ㅋ', 'ㅌ', 'ㅊ', 'ㅍ', 'ㅠ', 'ㅜ', 'ㅡ'],
  ['Space']
];

const arabicLayout = [
  ['ض', 'ص', 'ث', 'ق', 'ف', 'غ', 'ع', 'ه', 'خ', 'ح', 'ج'],
  ['ش', 'س', 'ي', 'ب', 'ل', 'ا', 'ت', 'ن', 'م', 'ك'],
  ['Shift', 'ئ', 'ء', 'ؤ', 'ر', 'ى', 'ة', 'و', 'ز', 'ظ', 'ط'],
  ['Space']
];

// Malay and Indonesian use standard Latin alphabet with some additional characters
const malayIndonesianLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'é', 'è', 'ë'],
  ['Space']
];

const urduLayout = [
  ['ق', 'و', 'ع', 'ر', 'ت', 'ے', 'ء', 'ی', 'ہ', 'پ'],
  ['ا', 'س', 'د', 'ف', 'گ', 'ح', 'ج', 'ک', 'ل'],
  ['Shift', 'ز', 'ش', 'چ', 'ط', 'ب', 'ن', 'م', 'ں', 'ۃ'],
  ['Space']
];

const bengaliLayout = [
  ['ঙ', 'ং', 'ে', 'র', 'ট', 'য', 'ু', 'ি', 'ো', 'প'],
  ['া', 'স', 'ড', 'ফ', 'গ', 'হ', 'জ', 'ক', 'ল'],
  ['Shift', 'য়', 'শ', 'চ', 'ব', 'ন', 'ম', 'ৎ', 'ঃ'],
  ['Space']
];

const punjabiLayout = [
  ['ੌ', 'ੈ', 'ਾ', 'ੀ', 'ੂ', 'ਬ', 'ਹ', 'ਗ', 'ਦ', 'ਜ', 'ਡ'],
  ['ੋ', 'ੇ', '੍', 'ਿ', 'ੁ', 'ਪ', 'ਰ', 'ਕ', 'ਤ', 'ਚ', 'ਟ'],
  ['Shift', 'ਂ', 'ਮ', 'ਨ', 'ਵ', 'ਲ', 'ਸ', 'ਯ', 'ਞ'],
  ['Space']
];

const vietnameseLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 'ă', 'â', 'd', 'đ', 'ê', 'g', 'h', 'k', 'l'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['ấ', 'ầ', 'ẩ', 'ẫ', 'ậ', 'ắ', 'ằ', 'ẳ', 'ẵ', 'ặ', 'ố', 'ồ', 'ổ', 'ỗ', 'ộ', 'ớ', 'ờ', 'ở', 'ỡ', 'ợ']
];

const swahiliLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['Space']
];

// Hausa includes hooked letters and tone marks
const hausaLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['ɓ', 'ɗ', 'ƙ', 'ƴ', 'à', 'á', 'è', 'é', 'ì', 'í', 'ò', 'ó', 'ù', 'ú'],
  ['Space']
];

// Yoruba includes tone marks and special characters
const yorubaLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['ẹ', 'ọ', 'ṣ', 'à', 'á', 'è', 'é', 'ì', 'í', 'ò', 'ó', 'ù', 'ú', 'ṣ̀', 'ṣ́'],
  ['Space']
];

// Igbo includes tone marks and special characters
const igboLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['ị', 'ụ', 'ọ', 'à', 'á', 'è', 'é', 'ì', 'í', 'ò', 'ó', 'ù', 'ú'],
  ['Space']
];

// Shona uses standard Latin alphabet with additional characters
const shonaLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['ʋ', 'ɗ', 'ŋ'],
  ['Space']
];

// Kinyarwanda uses standard Latin alphabet with some special characters
const kinyarwandaLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['ā', 'ē', 'ī', 'ō', 'ū'],
  ['Space']
];

// Kirundi is similar to Kinyarwanda
const kirundiLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['ā', 'ē', 'ī', 'ō', 'ū'],
  ['Space']
];

// Sesotho uses standard Latin alphabet with some diacritics
const sesothoLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['ê', 'ô', 'š'],
  ['Space']
];

// Chichewa uses standard Latin alphabet with some special characters
const chichewaLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['ŋ', 'ṅ'],
  ['Space']
];

// Add Japanese layouts
const hiraganaLayout = [
  ['わ', 'ら', 'や', 'ま', 'は', 'な', 'た', 'さ', 'か', 'あ'],
  ['を', 'り', 'ゆ', 'む', 'ふ', 'ぬ', 'つ', 'す', 'く', 'う'],
  ['ん', 'る', 'よ', 'も', 'ほ', 'の', 'て', 'せ', 'け', 'え'],
  ['⇋', 'れ', 'ゆ', 'み', 'ひ', 'に', 'ち', 'し', 'き', 'い'],
  ['Space']
];

const katakanaLayout = [
  ['ワ', 'ラ', 'ヤ', 'マ', 'ハ', 'ナ', 'タ', 'サ', 'カ', 'ア'],
  ['ヲ', 'リ', 'ユ', 'ム', 'フ', 'ヌ', 'ツ', 'ス', 'ク', 'ウ'],
  ['ン', 'ル', 'ヨ', 'モ', 'ホ', 'ノ', 'テ', 'セ', 'ケ', 'エ'],
  ['⇋', 'レ', 'ユ', 'ミ', 'ヒ', 'ニ', 'チ', 'シ', 'キ', 'イ'],
  ['Space']
];

// Add Chinese (Pinyin) layout
const chineseLayout = [
  ['ā', 'á', 'ǎ', 'à', 'ē', 'é', 'ě', 'è', 'ī', 'í', 'ǐ', 'ì'],
  ['ō', 'ó', 'ǒ', 'ò', 'ū', 'ú', 'ǔ', 'ù', 'ü', 'ǖ', 'ǘ', 'ǚ', 'ǜ'],
  ['Shift', 'ń', 'ň', 'ǹ', 'ḿ', 'm̀', 'ẑ', 'ĉ', 'ŝ'],
  ['Space']
];

// Add Dutch layout
const dutchLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'é', 'ë', 'ï', 'ü'],
  ['Space']
];

// Update layouts object to include Chinese
const layouts = {
  english: englishLayout,
  dutch: dutchLayout,
  german: germanLayout,
  russian: russianLayout,
  norwegian: norwegianLayout,
  danish: danishLayout,
  swedish: swedishLayout,
  finnish: finnishLayout,
  icelandic: icelandicLayout,
  french: frenchLayout,
  spanish: spanishLayout,
  italian: italianLayout,
  portuguese: portugueseLayout,
  ukrainian: ukrainianLayout,
  lithuanian: lithuanianLayout,
  latvian: latvianLayout,
  estonian: estonianLayout,
  polish: polishLayout,
  irish: irishLayout,
  faroese: faroeseLayout,
  belarusian: belarusianLayout,
  czech: czechLayout,
  slovak: slovakLayout,
  hungarian: hungarianLayout,
  romanian: romanianLayout,
  slovenian: slovenianLayout,
  basque: basqueLayout,
  galician: galicianLayout,
  bulgarian: bulgarianLayout,
  albanian: albanianLayout,
  greek: greekLayout,
  turkish: turkishLayout,
  sami: samiLayout,
  georgian: georgianLayout,
  croatian: southSlavicLayout,
  serbian: southSlavicLayout,
  bosnian: southSlavicLayout,
  montenegrin: southSlavicLayout,
  armenian: armenianLayout,
  catalan: catalanLayout,
  luxembourgish: luxembourgishLayout,
  kurdish: kurdishLatinLayout,
  hindi: hindiLayout,
  korean: koreanLayout,
  arabic: arabicLayout,
  malay: malayIndonesianLayout,
  indonesian: malayIndonesianLayout,
  urdu: urduLayout,
  bengali: bengaliLayout,
  punjabi: punjabiLayout,
  vietnamese: vietnameseLayout,
  swahili: swahiliLayout,
  hausa: hausaLayout,
  yoruba: yorubaLayout,
  igbo: igboLayout,
  shona: shonaLayout,
  kinyarwanda: kinyarwandaLayout,
  kirundi: kirundiLayout,
  sesotho: sesothoLayout,
  chichewa: chichewaLayout,
  japanese: hiraganaLayout, // Default to hiragana
  chinese: chineseLayout,
};

const Keyboard = ({ layout, onKeyPress }) => {
  const { theme } = useContext(ThemeContext);
  const [pressedKey, setPressedKey] = useState(null);
  const [isShiftActive, setIsShiftActive] = useState(false);
  const [japaneseMode, setJapaneseMode] = useState('hiragana');

  const handleKeyPress = (key) => {
    if (key === '⇋' && layout === 'japanese') {
      setJapaneseMode(japaneseMode === 'hiragana' ? 'katakana' : 'hiragana');
      return;
    }

    if (key === 'shift') {
      setIsShiftActive(!isShiftActive);
    } else if (key === 'enter') {
      onKeyPress('');
      document.querySelector('form').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    } else if (key === 'space') {
      onKeyPress('space');
    } else if (key === 'backspace') {
      onKeyPress('backspace');
    } else if (key.length === 1) {
      const finalKey = isShiftActive ? key.toUpperCase() : key;
      onKeyPress(finalKey);

      if (isShiftActive) {
        setIsShiftActive(false);
      }
    }

    setPressedKey(key.toLowerCase());
    setTimeout(() => setPressedKey(null), 100);
  };

  useEffect(() => {
    const handleRealKeyPress = (event) => {
      if (event.defaultPrevented) return;

      if (event.key === 'Shift') {
        setIsShiftActive(!isShiftActive);
        handleKeyPress('shift');
      } else if (event.key === ' ') {
        handleKeyPress('space');
      } else if (event.key === 'Enter') {
        handleKeyPress('enter');
      } else if (event.key === 'Backspace') {
        handleKeyPress('backspace');
      } else if (event.key.length === 1) {
        const key = isShiftActive ? event.key.toUpperCase() : event.key.toLowerCase();
        handleKeyPress(key);
      }
      event.preventDefault();
    };

    window.addEventListener('keydown', handleRealKeyPress);
    return () => {
      window.removeEventListener('keydown', handleRealKeyPress);
    };
  }, [isShiftActive]);

  // Get the current layout based on the selected layout and Japanese mode
  const getCurrentLayout = () => {
    if (layout === 'japanese') {
      return japaneseMode === 'hiragana' ? hiraganaLayout : katakanaLayout;
    }
    return layouts[layout];
  };

  const currentLayout = getCurrentLayout();

  return (
    <div className={`keyboard ${theme}`}>
      {currentLayout.map((row, rowIndex) => (
        <div key={rowIndex} className="keyboard-row">
          {row.map((key) => (
            <button
              key={key}
              className={`keyboard-key ${key.toLowerCase()} ${key === '⇋' ? 'switch-key' : ''} ${pressedKey === key.toLowerCase() ? 'pressed' : ''
                }`}
              onClick={() => handleKeyPress(key.toLowerCase())}
            >
              {key}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Keyboard;
