import React, { useState, useEffect, useRef } from 'react';
import './VocabCreator.css'; // Import the CSS file
import { useLanguage } from '../LanguageContext'; // Use the LanguageContext
import ReducedNavbar from '../Components/UI/ReducedNavbar';
import Modal from 'react-modal'; // Import a modal library
import Slider from 'react-slider'; // Import a slider library
import AddIcon from '@mui/icons-material/Add'; // Replace the FaPlus import with:
import LockIcon from '@mui/icons-material/Lock'; // Add this import at the top

function VocabCreator() {

    const { language } = useLanguage();

    const texts = {
        header: language === 'English' ? 'Vocabulary Deck Creator'
            : language === 'Deutsch' ? 'Vokabeldeck Ersteller'
                : language === 'Español' ? 'Creador de Mazos de Vocabulario'
                    : language === 'Français' ? 'Créateur de Jeux de Vocabulaire'
                        : language === 'Italiano' ? 'Creatore di Mazzi di Vocabolario'
                            : language === 'Português' ? 'Criador de Baralhos de Vocabulário'
                                : 'Создатель Словарных Колод',
        createDeck: language === 'English' ? 'Create Deck'
            : language === 'Deutsch' ? 'Erstelle Deck'
                : language === 'Español' ? 'Crear Mazo'
                    : language === 'Français' ? 'Créer un Jeu'
                        : language === 'Italiano' ? 'Crea Mazzo'
                            : language === 'Português' ? 'Criar Baralho'
                                : 'Создайте колоду',
        name: language === 'English' ? 'Name'
            : language === 'Deutsch' ? 'Name'
                : language === 'Español' ? 'Nombre'
                    : language === 'Français' ? 'Nom'
                        : language === 'Italiano' ? 'Nome'
                            : language === 'Português' ? 'Nome'
                                : 'Название',
        description: language === 'English' ? 'Description'
            : language === 'Deutsch' ? 'Beschreibung'
                : language === 'Español' ? 'Descripción'
                    : language === 'Français' ? 'Description'
                        : language === 'Italiano' ? 'Descrizione'
                            : language === 'Português' ? 'Descrição'
                                : 'Описание',
        tags: language === 'English' ? 'Tags'
            : language === 'Deutsch' ? 'Stichwörter'
                : language === 'Español' ? 'Etiquetas'
                    : language === 'Français' ? 'Tags'
                        : language === 'Italiano' ? 'Tag'
                            : language === 'Português' ? 'Tags'
                                : 'Теги',
        topics: language === 'English' ? 'Topics'
            : language === 'Deutsch' ? 'Themen'
                : language === 'Español' ? 'Temas'
                    : language === 'Français' ? 'Sujets'
                        : language === 'Italiano' ? 'Argomenti'
                            : language === 'Português' ? 'Tópicos'
                                : 'Темы',
        niveau: language === 'English' ? 'Level'
            : language === 'Deutsch' ? 'Niveau'
                : language === 'Español' ? 'Nivel'
                    : language === 'Français' ? 'Niveau'
                        : language === 'Italiano' ? 'Livello'
                            : language === 'Português' ? 'Nível'
                                : 'Уровень',
        languages: language === 'English' ? 'Languages'
            : language === 'Deutsch' ? 'Sprachen'
                : language === 'Español' ? 'Idiomas'
                    : language === 'Français' ? 'Langues'
                        : language === 'Italiano' ? 'Lingue'
                            : language === 'Português' ? 'Idiomas'
                                : 'Языки',
        createButton: language === 'English' ? 'Create'
            : language === 'Deutsch' ? 'Erstellen'
                : language === 'Español' ? 'Crear'
                    : language === 'Français' ? 'Créer'
                        : language === 'Italiano' ? 'Crea'
                            : language === 'Português' ? 'Criar'
                                : 'Создать',
        confirmButton: language === 'English' ? 'Confirm'
            : language === 'Deutsch' ? 'Bestätigen'
                : language === 'Español' ? 'Confirmar'
                    : language === 'Français' ? 'Confirmer'
                        : language === 'Italiano' ? 'Conferma'
                            : language === 'Português' ? 'Confirmar'
                                : 'Подтвердить',
        selectLevelRange: language === 'English' ? 'Select level range'
            : language === 'Deutsch' ? 'Niveaubereich auswählen'
                : language === 'Español' ? 'Seleccionar rango de nivel'
                    : language === 'Français' ? 'Sélectionner la plage de niveau'
                        : language === 'Italiano' ? 'Seleziona intervallo di livello'
                            : language === 'Português' ? 'Selecionar faixa de nível'
                                : 'Выберите диапазон уровней',
        elements: language === 'English' ? 'elements'
            : language === 'Deutsch' ? 'Elemente'
                : language === 'Español' ? 'elementos'
                    : language === 'Français' ? 'éléments'
                        : language === 'Italiano' ? 'elementi'
                            : language === 'Português' ? 'elementos'
                                : 'элементы',
        export: language === 'English' ? 'Export'
            : language === 'Deutsch' ? 'Exportieren'
                : language === 'Español' ? 'Exportar'
                    : language === 'Français' ? 'Exporter'
                        : language === 'Italiano' ? 'Esporta'
                            : language === 'Português' ? 'Exportar'
                                : 'Экспорт',
        publish: language === 'English' ? 'Publish'
            : language === 'Deutsch' ? 'Veröffentlichen'
                : language === 'Español' ? 'Publicar'
                    : language === 'Français' ? 'Publier'
                        : language === 'Italiano' ? 'Pubblica'
                            : language === 'Português' ? 'Publicar'
                                : 'Опубликовать',
        upload: language === 'English' ? 'Upload'
            : language === 'Deutsch' ? 'Hochladen'
                : language === 'Español' ? 'Subir'
                    : language === 'Français' ? 'Télécharger'
                        : language === 'Italiano' ? 'Carica'
                            : language === 'Português' ? 'Carregar'
                                : 'Загрузить',
        createFirst: language === 'English' ? 'Start by creating a new deck!'
            : language === 'Deutsch' ? 'Beginnen Sie mit der Erstellung eines neuen Decks!'
                : language === 'Español' ? '¡Comienza creando un nuevo mazo!'
                    : language === 'Français' ? 'Commencez par créer un nouveau jeu !'
                        : language === 'Italiano' ? 'Inizia creando un nuovo mazzo!'
                            : language === 'Português' ? 'Comece criando um novo baralho!'
                                : 'Начните с создания новой колоды!',
    };

    const placeholders = {
        deckName: language === 'English' ? 'Enter deck name'
            : language === 'Deutsch' ? 'Decknamen eingeben'
                : language === 'Español' ? 'Ingrese nombre del mazo'
                    : language === 'Français' ? 'Entrez le nom du jeu'
                        : language === 'Italiano' ? 'Inserisci nome del mazzo'
                            : language === 'Português' ? 'Digite o nome do baralho'
                                : 'Введите название колоды',
        description: language === 'English' ? 'Enter description'
            : language === 'Deutsch' ? 'Beschreibung eingeben'
                : language === 'Español' ? 'Ingrese descripción'
                    : language === 'Français' ? 'Entrez la description'
                        : language === 'Italiano' ? 'Inserisci descrizione'
                            : language === 'Português' ? 'Digite a descrição'
                                : 'Введите описание',
        addTag: '+',
        addTopic: '+',
        addLanguage: '+',
    };

    const descriptionRef = useRef(null);
    const [niveauRange, setNiveauRange] = useState([0, 6]); // Changed from [0, 5] to [0, 6]
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const niveauDropdownRef = useRef(null);
    const [description, setDescription] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [isTagModalOpen, setIsTagModalOpen] = useState(false);
    const [isTopicModalOpen, setIsTopicModalOpen] = useState(false);
    const [tagModalPosition, setTagModalPosition] = useState({ top: 0, left: 0 });
    const [topicModalPosition, setTopicModalPosition] = useState({ top: 0, left: 0 });
    const tagButtonRef = useRef(null);
    const topicButtonRef = useRef(null);
    const niveauButtonRef = useRef(null);
    const [isNiveauModalOpen, setIsNiveauModalOpen] = useState(false);
    const [niveauModalPosition, setNiveauModalPosition] = useState({ top: 0, left: 0 });
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
    const [languageModalPosition, setLanguageModalPosition] = useState({ top: 0, left: 0 });
    const languageButtonRef = useRef(null);

    const availableTags = [
        'Adjectives', 'Adverbs', 'Advanced', 'Articles', 'Beginner', 'Business',
        'Casual', 'Cognates', 'Collocations', 'Comparatives', 'Conjunctions',
        'False Friends', 'Formal', 'Future', 'Grammar', 'Idioms', 'Imperative',
        'Intermediate', 'Modal Verbs', 'Nouns', 'Past', 'Phrases', 'Phrasal Verbs',
        'Present', 'Pronouns', 'Sentence Structure', 'Slang', 'Subjunctive',
        'Superlatives', 'Tenses', 'Verbs', 'Word Order'
    ];
    const availableTopics = [
        'Animals', 'Art', 'Body Parts', 'Cities', 'Clothing', 'Colors',
        'Countries', 'Culture', 'Days of the Week', 'Directions', 'Education',
        'Emotions', 'Emergency', 'Entertainment', 'Environment', 'Family',
        'Finance', 'Food', 'Geography', 'Health', 'Hobbies', 'Holidays',
        'History', 'Household', 'Languages', 'Law', 'Leisure Activities',
        'Literature', 'Months', 'Movies', 'Music', 'Nature', 'Numbers',
        'Politics', 'Professions', 'Public Services', 'Restaurants', 'Science',
        'Shopping', 'Social Media', 'Sports', 'Technology', 'Time',
        'Tools', 'Transportation', 'Travel', 'Weather', 'Work'
    ];
    const availableLanguages = [
        'Shqip', 'العربية', 'Հայերեն', 'Euskara', 'Беларуская', 'বাংলা',
        'Bosanski', 'Български', 'Català', 'Chichewa', '中文', 'Hrvatski', 'Čeština',
        'Dansk', 'Deutsch', 'English', 'Eesti', 'Føroyskt', 'Suomi',
        'Français', 'Galego', 'ქართული', 'Ελληνικά', 'Hausa', 'हिन्दी',
        'Magyar', 'Íslenska', 'Igbo', 'Bahasa Indonesia', 'Gaeilge', 'Italiano',
        '日本語', 'Kinyarwanda', 'Kirundi', '한국어', 'Kurdî', 'Latviešu',
        'Lietuvių', 'Lëtzebuergesch', 'Bahasa Melayu', 'Crnogorski', 'Nederlands', 'Norsk',
        'Polski', 'Português', 'ਪੰਜਾਬੀ', 'Română', 'Русский', 'Sámegiella',
        'Српски', 'Sesotho', 'Shona', 'Slovenčina', 'Slovenščina',
        'Español', 'Kiswahili', 'Svenska', 'Türkçe', 'Українська',
        'اردو', 'Tiếng Việt', 'Yorùbá'
    ];

    const handleInput = () => {
        const textarea = descriptionRef.current;
        textarea.style.height = 'auto'; // Reset height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set to scroll height
    };

    const niveauLabels = ['Pre-A1', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
    const actualNiveaus = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];

    const handleNiveauChange = (newRange) => {
        if (newRange[1] - newRange[0] > 0) { // Ensure at least one category is between
            setNiveauRange(newRange);
        }
    };

    const getSelectedNiveaus = () => {
        const start = Math.max(niveauRange[0], 0); // Ensure start is at least 1 (A1)
        const end = Math.min(niveauRange[1], 6); // Ensure end is at most 6 (C2)
        const selectedNiveaus = actualNiveaus.slice(start, end);

        if (selectedNiveaus.length === 1) {
            return selectedNiveaus[0];
        } else if (selectedNiveaus.length > 1) {
            return `${selectedNiveaus[0]}-${selectedNiveaus[selectedNiveaus.length - 1]}`;
        }
        return '';
    };

    const handleDescriptionChange = (e) => {
        const input = e.target.value;
        if (input.length <= 500) { // Only update if within character limit
            setDescription(input);
            handleInput(); // Keep the auto-resize functionality
        }
    };

    const handleTagClick = (tag) => {
        setSelectedTags(prev =>
            prev.includes(tag)
                ? prev.filter(t => t !== tag)
                : [...prev, tag]
        );
    };

    const handleTopicClick = (topic) => {
        setSelectedTopics(prev =>
            prev.includes(topic)
                ? prev.filter(t => t !== topic)
                : [...prev, topic]
        );
    };

    const handleOpenTagModal = () => {
        const buttonRect = tagButtonRef.current.getBoundingClientRect();
        const createDeckRect = tagButtonRef.current.closest('.create-deck').getBoundingClientRect();

        // Calculate the height of the modal (approximate)
        const modalHeight = 300; // Adjust this value based on your modal's actual height

        let topPosition = buttonRect.bottom + window.scrollY;

        // Check if modal would extend below the create-deck container
        if (topPosition + modalHeight > createDeckRect.bottom) {
            // Position modal above the button instead
            topPosition = buttonRect.top - modalHeight + window.scrollY;
        }

        setTagModalPosition({
            top: topPosition,
            left: buttonRect.left + window.scrollX
        });
        setIsTagModalOpen(true);
    };

    const handleOpenTopicModal = () => {
        const buttonRect = topicButtonRef.current.getBoundingClientRect();
        const createDeckRect = topicButtonRef.current.closest('.create-deck').getBoundingClientRect();

        // Calculate the height of the modal (approximate)
        const modalHeight = 300; // Adjust this value based on your modal's actual height

        let topPosition = buttonRect.bottom + window.scrollY;

        // Check if modal would extend below the create-deck container
        if (topPosition + modalHeight > createDeckRect.bottom) {
            // Position modal above the button instead
            topPosition = buttonRect.top - modalHeight + window.scrollY;
        }

        setTopicModalPosition({
            top: topPosition,
            left: buttonRect.left + window.scrollX
        });
        setIsTopicModalOpen(true);
    };

    const handleOpenNiveauModal = () => {
        const buttonRect = niveauButtonRef.current.getBoundingClientRect();
        const createDeckRect = niveauButtonRef.current.closest('.create-deck').getBoundingClientRect();

        // Calculate the height of the modal (approximate)
        const modalHeight = 300; // Adjust this value based on your modal's actual height

        let topPosition = buttonRect.bottom + window.scrollY;

        // Check if modal would extend below the create-deck container
        if (topPosition + modalHeight > createDeckRect.bottom) {
            // Position modal above the button instead
            topPosition = buttonRect.top - modalHeight + window.scrollY;
        }

        setNiveauModalPosition({
            top: topPosition,
            left: buttonRect.left + window.scrollX
        });
        setIsNiveauModalOpen(true);
    };

    const handleLanguageClick = (language) => {
        setSelectedLanguages(prev =>
            prev.includes(language)
                ? prev.filter(l => l !== language)
                : [...prev, language]
        );
    };

    const handleOpenLanguageModal = () => {
        const buttonRect = languageButtonRef.current.getBoundingClientRect();
        const createDeckRect = languageButtonRef.current.closest('.create-deck').getBoundingClientRect();

        const modalHeight = 300;
        let topPosition = buttonRect.bottom + window.scrollY;

        if (topPosition + modalHeight > createDeckRect.bottom) {
            topPosition = buttonRect.top - modalHeight + window.scrollY;
        }

        setLanguageModalPosition({
            top: topPosition,
            left: buttonRect.left + window.scrollX
        });
        setIsLanguageModalOpen(true);
    };

    const [deckName, setDeckName] = useState('');
    const [isDeckCreated, setIsDeckCreated] = useState(false);
    const [vocabularyElements, setVocabularyElements] = useState([]);
    const [currentVocabInputs, setCurrentVocabInputs] = useState({});

    // Add handler for deck name input
    const handleDeckNameChange = (e) => {
        const input = e.target.value;
        if (input.length <= 33) { // Only update if within character limit
            setDeckName(input);
        }
    };

    // Handler for creating the deck
    const handleCreateDeck = () => {
        if (!deckName || selectedLanguages.length < 2) {
            alert(language === 'English'
                ? 'Please enter a deck name and select at least 2 languages!'
                : language === 'Deutsch'
                    ? 'Bitte geben Sie einen Deck-Namen ein und wählen Sie mindestens 2 Sprachen!'
                    : 'Пожалуйста, введите название колоды и выберите как минимум 2 языка!');
            return;
        }
        setIsDeckCreated(true);
        // Initialize vocabulary inputs for each language
        const inputs = {};
        selectedLanguages.forEach(lang => {
            inputs[lang] = '';
        });
        setCurrentVocabInputs(inputs);
    };

    // Handler for adding vocabulary
    const handleAddVocabulary = () => {
        // Check if all inputs have values
        const hasEmptyInputs = Object.values(currentVocabInputs).some(value => !value.trim());
        if (hasEmptyInputs) {
            alert(language === 'English'
                ? 'Please fill in all language fields!'
                : language === 'Deutsch'
                    ? 'Bitte füllen Sie alle Sprachfelder aus!'
                    : 'Пожалуйста, заполните все языковые поля!');
            return;
        }

        setVocabularyElements([...vocabularyElements, currentVocabInputs]);

        // Reset inputs
        const resetInputs = {};
        selectedLanguages.forEach(lang => {
            resetInputs[lang] = '';
        });
        setCurrentVocabInputs(resetInputs);
    };

    // Handler for vocabulary input changes
    const handleVocabInputChange = (language, value) => {
        setCurrentVocabInputs(prev => ({
            ...prev,
            [language]: value
        }));
    };

    // Add this hidden file input reference
    const fileInputRef = useRef(null);

    // Add this function to handle file upload button click
    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    // Add this function to process the uploaded file
    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            const lines = text.split('\n');
            const newVocabElements = [];

            lines.forEach(line => {
                // Skip empty lines
                if (line.trim() === '') return;

                // Split by comma and trim each element
                const elements = line.split(',').map(elem => elem.trim());

                // Only add if number of elements matches number of selected languages
                if (elements.length === selectedLanguages.length) {
                    const vocabObject = {};
                    selectedLanguages.forEach((lang, index) => {
                        vocabObject[lang] = elements[index];
                    });
                    newVocabElements.push(vocabObject);
                }
            });

            // Update state once with all new elements
            setVocabularyElements(prev => [...prev, ...newVocabElements]);
        };
        reader.readAsText(file);
        // Reset file input
        event.target.value = '';
    };

    // Add this function near your other handlers
    const handleDeleteVocabulary = (indexToDelete) => {
        setVocabularyElements(prev => prev.filter((_, index) => index !== indexToDelete));
    };

    // Add this function before the handleExport function
    const transformLanguageName = (language) => {
        const languageMap = {
            'Shqip': 'albanian',
            'العربية': 'arabic',
            'Հայերեն': 'armenian',
            'Euskara': 'basque',
            'Беларуская': 'belarusian',
            'বাংলা': 'bengali',
            'Bosanski': 'bosnian',
            'Български': 'bulgarian',
            'Català': 'catalan',
            'Chichewa': 'chichewa',
            '中文': 'chinese',
            'Hrvatski': 'croatian',
            'Čeština': 'czech',
            'Dansk': 'danish',
            'Deutsch': 'german',
            'English': 'english',
            'Eesti': 'estonian',
            'Føroyskt': 'faroese',
            'Suomi': 'finnish',
            'Français': 'french',
            'Galego': 'galician',
            'ქართული': 'georgian',
            'Ελληνικά': 'greek',
            'Hausa': 'hausa',
            'हिन्दी': 'hindi',
            'Magyar': 'hungarian',
            'Íslenska': 'icelandic',
            'Igbo': 'igbo',
            'Bahasa Indonesia': 'indonesian',
            'Gaeilge': 'irish',
            'Italiano': 'italian',
            '日本語': 'japanese',
            'Kinyarwanda': 'kinyarwanda',
            'Kirundi': 'kirundi',
            '한국어': 'korean',
            'Kurdî': 'kurdish',
            'Latviešu': 'latvian',
            'Lietuvių': 'lithuanian',
            'Lëtzebuergesch': 'luxembourgish',
            'Bahasa Melayu': 'malay',
            'Crnogorski': 'montenegrin',
            'Nederlands': 'dutch',
            'Norsk': 'norwegian',
            'Polski': 'polish',
            'Português': 'portuguese',
            'ਪੰਜਾਬੀ': 'punjabi',
            'Română': 'romanian',
            'Русский': 'russian',
            'Sámegiella': 'sami',
            'Српски': 'serbian',
            'Sesotho': 'sesotho',
            'Shona': 'shona',
            'Slovenčina': 'slovak',
            'Slovenščina': 'slovenian',
            'Español': 'spanish',
            'Kiswahili': 'swahili',
            'Svenska': 'swedish',
            'Türkçe': 'turkish',
            'Українська': 'ukrainian',
            'اردو': 'urdu',
            'Tiếng Việt': 'vietnamese',
            'Yorùbá': 'yoruba'
        };
        return languageMap[language] || language.toLowerCase();
    };

    // Update the handleExport function
    const handleExport = () => {
        // Create the export object
        const exportData = {
            title: deckName,
            description: description,
            tags: selectedTags,
            topics: selectedTopics,
            languages: selectedLanguages.map(lang => transformLanguageName(lang)), // Transform language names
            niveau: getSelectedNiveaus(),
            data: vocabularyElements.map(item => {
                // Create an object with all language entries
                const wordEntry = {};
                selectedLanguages.forEach(lang => {
                    // Transform the language name when creating the key
                    const transformedLang = transformLanguageName(lang);
                    wordEntry[transformedLang] = item[lang];
                });
                return wordEntry;
            })
        };

        // Convert to JSON string
        const jsonString = JSON.stringify(exportData, null, 2);

        // Create blob and download link
        const blob = new Blob([jsonString], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${deckName.replace(/\s+/g, '_')}.json`;

        // Trigger download
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    // Add this near your other state declarations
    const [jsonFileInputRef] = useState(useRef(null));

    // Add this function to handle JSON file upload
    const handleJsonUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const jsonData = JSON.parse(e.target.result);

                // Set all the state values from the JSON
                setDeckName(jsonData.title || '');
                setDescription(jsonData.description || '');
                setSelectedTags(jsonData.tags || []);
                setSelectedTopics(jsonData.topics || []);

                // Transform language names back to display format
                const transformedLanguages = (jsonData.languages || []).map(lang => {
                    switch (lang) {
                        case 'german': return 'Deutsch';
                        case 'russian': return 'Русский';
                        case 'norwegian': return 'Norsk';
                        case 'english': return 'English';
                        default: return lang.charAt(0).toUpperCase() + lang.slice(1);
                    }
                });
                setSelectedLanguages(transformedLanguages);

                // Set niveau range based on the niveau string
                const niveauStr = jsonData.niveau || '';
                const [start, end] = niveauStr.split('-');
                const startIdx = actualNiveaus.indexOf(start);
                const endIdx = end ? actualNiveaus.indexOf(end) + 1 : startIdx + 1;
                setNiveauRange([startIdx, endIdx]);

                // Transform vocabulary data
                const transformedVocab = (jsonData.data || []).map(item => {
                    const vocabEntry = {};
                    transformedLanguages.forEach(lang => {
                        const jsonKey = transformLanguageName(lang);
                        vocabEntry[lang] = item[jsonKey];
                    });
                    return vocabEntry;
                });
                setVocabularyElements(transformedVocab);

                // Set deck as created
                setIsDeckCreated(true);

            } catch (error) {
                alert('Error parsing JSON file. Please ensure it\'s in the correct format.');
                console.error('Error parsing JSON:', error);
            }
        };
        reader.readAsText(file);
        event.target.value = ''; // Reset file input
    };

    // Update the create-deck button section to include the upload button
    const createDeckButtons = (
        <div className='create-deck-buttons'>
            <button
                className='create-button'
                onClick={handleCreateDeck}
                disabled={isDeckCreated}
            >
                {texts.createButton}
            </button>
            <button
                className='upload-button'
                onClick={() => jsonFileInputRef.current.click()}
                disabled={isDeckCreated}
            >
                {texts.upload}
            </button>
            <input
                type="file"
                ref={jsonFileInputRef}
                style={{ display: 'none' }}
                accept=".json"
                onChange={handleJsonUpload}
            />
        </div>
    );

    return (
        <div className='vocabcreator-container'>
            <ReducedNavbar />
            <h2>{texts.header}</h2>
            <div className='vocabcreator-layout'>
                <div className='left-column'>
                    <div className='create-deck'>
                        <h3>{texts.createDeck}</h3>
                        <div className='input-group'>
                            <label>{texts.name}</label>
                            <input
                                type='text'
                                placeholder={placeholders.deckName}
                                className='vocabcreator-name-input'
                                value={deckName}
                                onChange={handleDeckNameChange}
                                maxLength={33} // Add maxLength as a backup
                            />
                        </div>
                        <div className='input-group'>
                            <label>{texts.description}</label>
                            <textarea
                                ref={descriptionRef}
                                placeholder={placeholders.description}
                                className='description-input'
                                value={description}
                                onChange={handleDescriptionChange}
                                maxLength={500} // Add maxLength as a backup
                            />
                        </div>
                        <div className='tags-section'>
                            <label>{texts.tags}</label>
                            <div className='tags'>
                                {selectedTags.map(tag => (
                                    <span key={tag} className='tag' onClick={() => handleTagClick(tag)}>
                                        {tag}
                                    </span>
                                ))}
                                <span
                                    ref={tagButtonRef}
                                    className='add-tag'
                                    onClick={handleOpenTagModal}
                                >
                                    <AddIcon fontSize="small" />
                                </span>
                            </div>
                        </div>
                        <div className='topics-section'>
                            <label>{texts.topics}</label>
                            <div className='topics'>
                                {selectedTopics.map(topic => (
                                    <span key={topic} className='topic' onClick={() => handleTopicClick(topic)}>
                                        {topic}
                                    </span>
                                ))}
                                <span
                                    ref={topicButtonRef}
                                    className='add-topic'
                                    onClick={handleOpenTopicModal}
                                >
                                    <AddIcon fontSize="small" />
                                </span>
                            </div>
                        </div>
                        <div className='niveau-section'>
                            <label>{texts.niveau}</label>
                            <div
                                className='niveau-dropdown'
                                ref={niveauDropdownRef}
                                onClick={() => {
                                    const dropdownRect = niveauDropdownRef.current.getBoundingClientRect();
                                    setModalPosition({
                                        top: dropdownRect.bottom + window.scrollY,
                                        left: dropdownRect.left + window.scrollX
                                    });
                                    setIsModalOpen(true);
                                }}
                            >
                                {getSelectedNiveaus()}
                            </div>
                        </div>
                        <div className='languages-section'>
                            <label>{texts.languages}</label>
                            <div className='languages'>
                                {selectedLanguages.map(language => (
                                    <span
                                        key={language}
                                        className='language'
                                        onClick={isDeckCreated ? null : () => handleLanguageClick(language)}
                                        style={isDeckCreated ? { cursor: 'not-allowed' } : {}}
                                    >
                                        {language}
                                    </span>
                                ))}
                                {!isDeckCreated && (
                                    <span
                                        ref={languageButtonRef}
                                        className='add-language'
                                        onClick={handleOpenLanguageModal}
                                    >
                                        <AddIcon fontSize="small" />
                                    </span>
                                )}
                            </div>
                        </div>
                        {createDeckButtons}
                    </div>
                </div>

                <div className='middle-column'>
                    <div className='middle-container-top'>
                        {isDeckCreated ? (
                            <div className='vocabulary-input-container'>
                                <h3>{language === 'English' ? 'Add Vocabulary' : language === 'Deutsch' ? 'Vokabeln hinzufügen' : 'Добавить словарь'}</h3>
                                {selectedLanguages.map(lang => (
                                    <div key={lang} className='vocab-input-group'>
                                        <label>{lang}</label>
                                        <input
                                            type='text'
                                            value={currentVocabInputs[lang] || ''}
                                            onChange={(e) => handleVocabInputChange(lang, e.target.value)}
                                            placeholder={`${lang} word...`}
                                        />
                                    </div>
                                ))}
                                <div className='vocab-buttons'>
                                    <button onClick={handleAddVocabulary}>
                                        {language === 'English' ? 'Add' : language === 'Deutsch' ? 'Hinzufügen' : 'Добавить'}
                                    </button>
                                    <button onClick={handleUploadClick}>
                                        {texts.upload}
                                    </button>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        accept=".txt"
                                        onChange={handleFileUpload}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className='locked-container'>
                                <LockIcon className='lock-icon' />
                                <p>{texts.createFirst}</p>
                            </div>
                        )}
                    </div>
                    <div className='middle-container-bottom'>
                        {isDeckCreated ? (
                            <div className='deck-summary'>
                                <h3>{language === 'English' ? 'Deck Summary' : language === 'Deutsch' ? 'Deck Übersicht' : 'Обзор колоды'}</h3>
                                <p className="deck-title">{deckName}</p>
                                <p>
                                    <span className="element-count">{vocabularyElements.length}</span>
                                    {' '}
                                    <span className="element-text">{texts.elements}</span>
                                </p>
                                <p>{selectedLanguages.join(', ')}</p>
                                <p>{getSelectedNiveaus()}</p>
                                <div className='deck-summary-buttons'>
                                    <button onClick={handleExport}>
                                        {texts.export}
                                    </button>
                                    <button onClick={() => alert(language === 'English'
                                        ? 'Publishing feature coming soon!'
                                        : language === 'Deutsch'
                                            ? 'Veröffentlichungsfunktion kommt bald!'
                                            : 'Функция публикации скоро появится!')}>
                                        {texts.publish}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className='locked-container'>
                                <LockIcon className='lock-icon' />
                                <p>{texts.createFirst}</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className='right-column'>
                    <div className='right-container'>
                        {isDeckCreated ? (
                            <>
                                <div className='deck-header'>
                                    <h2>{deckName}</h2>
                                    <p>
                                        <span className="element-count">{vocabularyElements.length}</span>
                                        {' '}
                                        <span className="element-text">{texts.elements}</span>
                                    </p>
                                </div>
                                {vocabularyElements.length > 0 && (
                                    <div className='vocabulary-table'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    {selectedLanguages.map(lang => (
                                                        <th key={lang}>{lang}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {vocabularyElements.map((element, index) => (
                                                    <tr
                                                        key={index}
                                                        onClick={() => handleDeleteVocabulary(index)}
                                                        className="vocabulary-row"
                                                    >
                                                        {selectedLanguages.map(lang => (
                                                            <td key={lang}>{element[lang]}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className='locked-container'>
                                <LockIcon className='lock-icon' />
                                <p>{texts.createFirst}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                className="niveau-modal"
                overlayClassName="niveau-modal-overlay"
                style={{
                    content: {
                        position: 'absolute',
                        top: `${modalPosition.top}px`,
                        left: `${modalPosition.left}px`,
                        width: '300px',
                        transform: 'none'
                    }
                }}
            >
                <h2>{texts.selectLevelRange}</h2>
                <div className="niveau-labels-container">
                    {actualNiveaus.map((niveau, index) => (
                        <span
                            key={niveau}
                            className="niveau-label"
                            style={{ left: `${(index + 1) * (100 / 6)}%` }}
                        >
                            {niveau}
                        </span>
                    ))}
                </div>
                <Slider
                    value={niveauRange}
                    min={0}
                    max={6}
                    step={1}
                    onChange={handleNiveauChange}
                    className="react-slider"
                    trackClassName="track"
                    renderThumb={(props) => <div {...props} />}
                />
                <button onClick={() => setIsModalOpen(false)}>{texts.confirmButton}</button>
            </Modal>

            <Modal
                isOpen={isTagModalOpen}
                onRequestClose={() => setIsTagModalOpen(false)}
                className="tags-modal"
                overlayClassName="niveau-modal-overlay"
                style={{
                    content: {
                        position: 'absolute',
                        top: `${tagModalPosition.top}px`,
                        left: `${tagModalPosition.left}px`,
                        transform: 'none'
                    }
                }}
            >
                <h2>{texts.tags}</h2>
                <div className="modal-items">
                    {availableTags.map(tag => (
                        <span
                            key={tag}
                            className={`modal-item ${selectedTags.includes(tag) ? 'selected' : ''}`}
                            onClick={() => handleTagClick(tag)}
                        >
                            {tag}
                        </span>
                    ))}
                </div>
                <button onClick={() => setIsTagModalOpen(false)}>{texts.confirmButton}</button>
            </Modal>

            <Modal
                isOpen={isTopicModalOpen}
                onRequestClose={() => setIsTopicModalOpen(false)}
                className="topics-modal"
                overlayClassName="niveau-modal-overlay"
                style={{
                    content: {
                        position: 'absolute',
                        top: `${topicModalPosition.top}px`,
                        left: `${topicModalPosition.left}px`,
                        transform: 'none'
                    }
                }}
            >
                <h2>{texts.topics}</h2>
                <div className="modal-items">
                    {availableTopics.map(topic => (
                        <span
                            key={topic}
                            className={`modal-item ${selectedTopics.includes(topic) ? 'selected' : ''}`}
                            onClick={() => handleTopicClick(topic)}
                        >
                            {topic}
                        </span>
                    ))}
                </div>
                <button onClick={() => setIsTopicModalOpen(false)}>{texts.confirmButton}</button>
            </Modal>

            <Modal
                isOpen={isLanguageModalOpen}
                onRequestClose={() => setIsLanguageModalOpen(false)}
                className="topics-modal"
                overlayClassName="niveau-modal-overlay"
                style={{
                    content: {
                        position: 'absolute',
                        top: `${languageModalPosition.top}px`,
                        left: `${languageModalPosition.left}px`,
                        transform: 'none'
                    }
                }}
            >
                <h2>{texts.languages}</h2>
                <div className="modal-items">
                    {availableLanguages.map(language => (
                        <span
                            key={language}
                            className={`modal-item ${selectedLanguages.includes(language) ? 'selected' : ''}`}
                            onClick={() => handleLanguageClick(language)}
                        >
                            {language}
                        </span>
                    ))}
                </div>
                <button onClick={() => setIsLanguageModalOpen(false)}>{texts.confirmButton}</button>
            </Modal>
        </div>
    );
}

export default VocabCreator;
