import React from 'react';
import LanguageCourse from '../../Components/LanguageCourse/LanguageCourse';
import { useRussianCourse } from '../../data/russianLessons';

const RussianLessons = () => {
    const { russianLessons, russianTrainers, russianCourseStats, courseName } = useRussianCourse();

    return (
        <LanguageCourse
            countryCode="RU"
            languageName={courseName}
            lessons={russianLessons}
            courseStats={russianCourseStats}
            trainers={russianTrainers}
        />
    );
};

export default RussianLessons;
