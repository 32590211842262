// No need to import MUI icons here as they're used in the Stories component

export const storyCollection = {
    german: {
        stories: [
            {
                id: 'de-story-1',
                type: 'story',
                title: 'Der kleine Hund',
                duration: 5,
                path: '/stories/german/der-kleine-hund',
                language: 'German',
                level: 'A1'
            },
            {
                id: 'de-chat-1',
                type: 'chat',
                title: 'Im Café',
                duration: 7,
                path: '/stories/german/im-cafe',
                language: 'German',
                level: 'A1'
            },
            {
                id: 'de-article-1',
                type: 'article',
                title: 'Deutsche Kultur',
                duration: 10,
                path: '/stories/german/deutsche-kultur',
                language: 'German',
                level: 'A2'
            },
            {
                id: 'de-story-2',
                type: 'story',
                title: 'Der große Bär',
                duration: 6,
                path: '/stories/german/der-grosse-baer',
                language: 'German',
                level: 'A2'
            },
            {
                id: 'de-chat-2',
                type: 'chat',
                title: 'Die Uhr der verlorenen Zeit',
                duration: 8,
                path: '/stories/german/die-uhr-der-verlorenen-zeit',
                language: 'German',
                level: 'A2'
            },
            {
                id: 'de-article-2',
                type: 'article',
                title: 'Das deutsche Schulsystem',
                duration: 15,
                path: '/stories/german/schulsystem',
                language: 'German',
                level: 'B1'
            },
            {
                id: 'de-story-3',
                type: 'story',
                title: 'Die verlorene Stadt',
                duration: 20,
                path: '/stories/german/verlorene-stadt',
                language: 'German',
                level: 'B2'
            },
            {
                id: 'de-chat-3',
                type: 'chat',
                title: 'Geschäftsverhandlung',
                duration: 12,
                path: '/stories/german/verhandlung',
                language: 'German',
                level: 'C1'
            }
        ]
    },
    russian: {
        stories: [
            {
                id: 'ru-story-1',
                type: 'story',
                title: 'Маленькая Собака',
                duration: 5,
                path: '/stories/russian/malenkaya-sobaka',
                language: 'Russian',
                level: 'A1'
            },
            {
                id: 'ru-chat-1',
                type: 'chat',
                title: 'В Ресторане',
                duration: 6,
                path: '/stories/russian/v-restorane',
                language: 'Russian',
                level: 'A1'
            },
            {
                id: 'ru-article-1',
                type: 'article',
                title: 'Русские Традиции',
                duration: 12,
                path: '/stories/russian/traditsii',
                language: 'Russian',
                level: 'A2'
            },
            {
                id: 'ru-story-2',
                type: 'story',
                title: 'Зимняя Сказка',
                duration: 15,
                path: '/stories/russian/zimnyaya-skazka',
                language: 'Russian',
                level: 'B1'
            },
            {
                id: 'ru-chat-2',
                type: 'chat',
                title: 'Деловая Встреча',
                duration: 10,
                path: '/stories/russian/delovaya-vstrecha',
                language: 'Russian',
                level: 'B2'
            }
        ]
    },
    english: {
        stories: [
            {
                id: 'en-story-1',
                type: 'story',
                title: 'The Lost Key',
                duration: 5,
                path: '/stories/english/lost-key',
                language: 'English',
                level: 'A1'
            },
            {
                id: 'en-chat-1',
                type: 'chat',
                title: 'At the Hotel',
                duration: 8,
                path: '/stories/english/at-hotel',
                language: 'English',
                level: 'A2'
            },
            {
                id: 'en-article-1',
                type: 'article',
                title: 'British Culture',
                duration: 15,
                path: '/stories/english/british-culture',
                language: 'English',
                level: 'B1'
            },
            {
                id: 'en-story-2',
                type: 'story',
                title: 'Mystery at Midnight',
                duration: 20,
                path: '/stories/english/midnight-mystery',
                language: 'English',
                level: 'B2'
            }
        ]
    },
    norwegian: {
        stories: [
            {
                id: 'no-story-1',
                type: 'story',
                title: 'Eirik og Forbannelsens Lenker',
                duration: 8,
                path: '/stories/norwegian/eirik-og-forbannelsens-lenker',
                language: 'Norwegian',
                level: 'A1'
            },
            {
                id: 'no-chat-1',
                type: 'chat',
                title: 'På Kafé',
                duration: 5,
                path: '/stories/norwegian/pa-kafe',
                language: 'Norwegian',
                level: 'A1'
            },
            {
                id: 'no-article-1',
                type: 'article',
                title: 'Norske Tradisjoner',
                duration: 12,
                path: '/stories/norwegian/norske-tradisjoner',
                language: 'Norwegian',
                level: 'A2'
            },
            {
                id: 'no-story-2',
                type: 'story',
                title: 'Trollet i Fjellet',
                duration: 15,
                path: '/stories/norwegian/trollet-i-fjellet',
                language: 'Norwegian',
                level: 'A2'
            },
            {
                id: 'no-chat-2',
                type: 'chat',
                title: 'På Turistkontoret',
                duration: 10,
                path: '/stories/norwegian/pa-turistkontoret',
                language: 'Norwegian',
                level: 'B1'
            },
            {
                id: 'no-article-2',
                type: 'article',
                title: 'Livet i Nord',
                duration: 18,
                path: '/stories/norwegian/livet-i-nord',
                language: 'Norwegian',
                level: 'B1'
            },
            {
                id: 'no-story-3',
                type: 'story',
                title: 'Midnattssolen',
                duration: 20,
                path: '/stories/norwegian/midnattssolen',
                language: 'Norwegian',
                level: 'B2'
            },
            {
                id: 'no-chat-3',
                type: 'chat',
                title: 'Jobbintervju',
                duration: 15,
                path: '/stories/norwegian/jobbintervju',
                language: 'Norwegian',
                level: 'C1'
            }
        ]
    }
};
