import React, { useState, useRef } from 'react';
import './Stories.css';
import { useNavigate } from 'react-router-dom';
import ReducedNavbar from '../Components/UI/ReducedNavbar';
import Searchbar from '../Components/UI/Searchbar';
import {
    BookOutlined,  // for stories
    ChatBubbleOutline,  // for chats
    ArticleOutlined,  // for articles
    CheckBox,
    CheckBoxOutlineBlank,
    LockOutlined
} from '@mui/icons-material';
import ReactCountryFlag from 'react-country-flag';
import { useLanguage } from '../LanguageContext';
import { storyCollection } from '../data/stories/StoryCollection';
import Slider from 'react-slider';
import Modal from 'react-modal';

// Keep only the story-related texts
const getStoriesTexts = (language) => ({
    story: language === 'English' ? 'Story'
        : language === 'Deutsch' ? 'Geschichte'
            : language === 'Español' ? 'Historia'
                : language === 'Français' ? 'Histoire'
                    : language === 'Italiano' ? 'Storia'
                        : language === 'Português' ? 'História'
                            : 'История',
    quiz: language === 'English' ? 'Quiz'
        : language === 'Deutsch' ? 'Quiz'
            : language === 'Español' ? 'Cuestionario'
                : language === 'Français' ? 'Quiz'
                    : language === 'Italiano' ? 'Quiz'
                        : language === 'Português' ? 'Questionário'
                            : 'Викторина',
    stories: language === 'English' ? 'Stories'
        : language === 'Deutsch' ? 'Geschichten'
            : language === 'Español' ? 'Historias'
                : language === 'Français' ? 'Histoires'
                    : language === 'Italiano' ? 'Storie'
                        : language === 'Português' ? 'Histórias'
                            : 'Истории',
    storyCollection: language === 'English' ? 'Story Collection'
        : language === 'Deutsch' ? 'Geschichtensammlung'
            : language === 'Español' ? 'Colección de Historias'
                : language === 'Français' ? 'Collection d\'Histoires'
                    : language === 'Italiano' ? 'Raccolta di Storie'
                        : language === 'Português' ? 'Coleção de Histórias'
                            : 'Коллекция историй',
    level: language === 'English' ? 'Level'
        : language === 'Deutsch' ? 'Niveau'
            : language === 'Español' ? 'Nivel'
                : language === 'Français' ? 'Niveau'
                    : language === 'Italiano' ? 'Livello'
                        : language === 'Português' ? 'Nível'
                            : 'Уровень',
    duration: language === 'English' ? 'Duration (min)'
        : language === 'Deutsch' ? 'Dauer (Min)'
            : language === 'Español' ? 'Duración (min)'
                : language === 'Français' ? 'Durée (min)'
                    : language === 'Italiano' ? 'Durata (min)'
                        : language === 'Português' ? 'Duração (min)'
                            : 'Длительность (мин)',
    language: language === 'English' ? 'Language'
        : language === 'Deutsch' ? 'Sprache'
            : language === 'Español' ? 'Idioma'
                : language === 'Français' ? 'Langue'
                    : language === 'Italiano' ? 'Lingua'
                        : language === 'Português' ? 'Idioma'
                            : 'Язык',
    languages: language === 'English' ? 'Languages'
        : language === 'Deutsch' ? 'Sprachen'
            : language === 'Español' ? 'Idiomas'
                : language === 'Français' ? 'Langues'
                    : language === 'Italiano' ? 'Lingue'
                        : language === 'Português' ? 'Idiomas'
                            : 'Языки',
    type: language === 'English' ? 'Type'
        : language === 'Deutsch' ? 'Typ'
            : language === 'Español' ? 'Tipo'
                : language === 'Français' ? 'Type'
                    : language === 'Italiano' ? 'Tipo'
                        : language === 'Português' ? 'Tipo'
                            : 'Тип',
    confirm: language === 'English' ? 'Confirm'
        : language === 'Deutsch' ? 'Bestätigen'
            : language === 'Español' ? 'Confirmar'
                : language === 'Français' ? 'Confirmer'
                    : language === 'Italiano' ? 'Confermare'
                        : language === 'Português' ? 'Confirmar'
                            : 'Подтвердить'
});

const isStoryLocked = (currentIndex, stories) => {
    for (let i = 0; i < currentIndex; i++) {
        if (stories[i].type === 'quiz' && stories[i].progress < 100) {
            return {
                locked: true,
                quizNumber: stories[i].classifier.split(' ')[1]
            };
        }
    }
    return { locked: false };
};

const getLanguageCode = (language) => {
    const languageCodes = {
        'German': 'DE',
        'Russian': 'RU',
        'Norwegian': 'NO'
        // Add more languages as needed
    };
    return languageCodes[language] || 'GB';
};

const getContentIcon = (type) => {
    switch (type) {
        case 'chat':
            return <ChatBubbleOutline className="story-icon" />;
        case 'article':
            return <ArticleOutlined className="story-icon" />;
        case 'story':
        default:
            return <BookOutlined className="story-icon" />;
    }
};

// Add this transliteration map near the top of the file
const cyrillicToLatin = {
    'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo',
    'ж': 'zh', 'з': 'z', 'и': 'i', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm',
    'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
    'ф': 'f', 'х': 'h', 'ц': 'ts', 'ч': 'ch', 'ш': 'sh', 'щ': 'sch', 'ъ': '',
    'ы': 'y', 'ь': '', 'э': 'e', 'ю': 'yu', 'я': 'ya',
    'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'E', 'Ё': 'Yo',
    'Ж': 'Zh', 'З': 'Z', 'И': 'I', 'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M',
    'Н': 'N', 'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U',
    'Ф': 'F', 'Х': 'H', 'Ц': 'Ts', 'Ч': 'Ch', 'Ш': 'Sh', 'Щ': 'Sch', 'Ъ': '',
    'Ы': 'Y', 'Ь': '', 'Э': 'E', 'Ю': 'Yu', 'Я': 'Ya'
};

// Add this helper function
const transliterate = (text) => {
    return text.split('').map(char => cyrillicToLatin[char] || char).join('');
};

// Add this near the top of the file with other constants
const languageAliases = {
    'Norsk': 'Norwegian',
    'Deutsch': 'German',
    'Русский': 'Russian',
    // Add more aliases as needed
};

// Add this mapping for display names
const nativeLanguageNames = {
    'German': 'Deutsch',
    'Russian': 'Русский',
    'Norwegian': 'Norsk',
    // Keep other languages as they are
};

const Stories = () => {
    const navigate = useNavigate();
    const { language } = useLanguage();
    const texts = getStoriesTexts(language);
    const [niveauRange, setNiveauRange] = useState([0, 6]);
    const [durationRange, setDurationRange] = useState([0, 60]);
    const [selectedLanguages, setSelectedLanguages] = useState(['German', 'Russian', 'English']);
    const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
    const [languageModalPosition, setLanguageModalPosition] = useState({ top: 0, left: 0 });
    const languageButtonRef = useRef(null);
    const [selectedTypes, setSelectedTypes] = useState(['story', 'chat', 'article']);
    const [isTypeModalOpen, setIsTypeModalOpen] = useState(false);
    const [typeModalPosition, setTypeModalPosition] = useState({ top: 0, left: 0 });
    const typeButtonRef = useRef(null);

    const availableLanguages = [
        'Shqip', 'العربية', 'Հայերեն', 'Euskara', 'Беларуская', 'বাংলা',
        'Bosanski', 'Български', 'Català', 'Chichewa', '中文', 'Hrvatski', 'Čeština',
        'Dansk', 'Deutsch', 'English', 'Eesti', 'Føroyskt', 'Suomi',
        'Français', 'Galego', 'ქართული', 'Ελληνικά', 'Hausa', 'हिन्दी',
        'Magyar', 'Íslenska', 'Igbo', 'Bahasa Indonesia', 'Gaeilge', 'Italiano',
        '日本語', 'Kinyarwanda', 'Kirundi', '한국어', 'Kurdî', 'Latviešu',
        'Lietuvių', 'Lëtzebuergesch', 'Bahasa Melayu', 'Crnogorski', 'Nederlands', 'Norsk',
        'Polski', 'Português', 'ਪੰਜਾਬੀ', 'Română', 'Русский', 'Sámegiella',
        'Српски', 'Sesotho', 'Shona', 'Slovenčina', 'Slovenščina',
        'Español', 'Kiswahili', 'Svenska', 'Türkçe', 'Українська',
        'اردو', 'Tiếng Việt', 'Yorùbá'
    ];

    const availableTypes = ['story', 'chat', 'article'];

    const handleLanguageClick = (language) => {
        setSelectedLanguages(prev => {
            // Convert the clicked language if it has an alias
            const normalizedLanguage = languageAliases[language] || language;

            const newSelection = prev.includes(normalizedLanguage)
                ? prev.filter(l => l !== normalizedLanguage)
                : [...prev, normalizedLanguage];

            console.log({
                clicked: language,
                normalized: normalizedLanguage,
                previousSelection: prev,
                newSelection
            });

            return newSelection;
        });
    };

    const handleOpenLanguageModal = () => {
        const buttonRect = languageButtonRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Calculate position, ensuring the modal stays on screen
        let top = buttonRect.bottom + window.scrollY;

        // If the modal would extend below the viewport, position it above the button instead
        if (top + 400 > windowHeight) { // 400 is an approximate modal height
            top = Math.max(20, buttonRect.top - 300); // Ensure it doesn't go above viewport
        }

        setLanguageModalPosition({
            top: top,
            left: Math.max(20, buttonRect.left + window.scrollX) // Ensure it's not too far left
        });
        setIsLanguageModalOpen(true);
    };

    const handleTypeClick = (type) => {
        setSelectedTypes(prev => {
            if (prev.length === 1 && prev.includes(type)) {
                return prev;
            }
            return prev.includes(type)
                ? prev.filter(t => t !== type)
                : [...prev, type];
        });
    };

    const handleOpenTypeModal = () => {
        const buttonRect = typeButtonRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        let top = buttonRect.bottom + window.scrollY;
        if (top + 400 > windowHeight) {
            top = Math.max(20, buttonRect.top - 400);
        }
        setTypeModalPosition({
            top: top,
            left: Math.max(20, buttonRect.left + window.scrollX)
        });
        setIsTypeModalOpen(true);
    };

    const getLevelIndex = (level) => {
        const levels = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
        return levels.indexOf(level);
    };

    const formatDuration = (duration) => `~${duration} min`;

    const filteredStories = React.useMemo(() => {
        const stories = Object.values(storyCollection)
            .flatMap(collection => collection.stories)
            .filter(story => {
                const levelIndex = getLevelIndex(story.level);
                const storyLanguage = story.language;  // No need to convert here anymore

                const isInLevelRange = levelIndex >= niveauRange[0] && levelIndex <= niveauRange[1] - 1;
                const isInDurationRange = story.duration >= durationRange[0] && story.duration <= durationRange[1];
                const isLanguageSelected = selectedLanguages.includes(storyLanguage);
                const isTypeSelected = selectedTypes.includes(story.type);

                if (story.language === 'Norwegian') {
                    console.log('Norwegian story filtering:', {
                        title: story.title,
                        language: story.language,
                        isLanguageSelected,
                        selectedLanguages,
                        isInLevelRange,
                        isInDurationRange,
                        isTypeSelected
                    });
                }

                return isInLevelRange &&
                    isInDurationRange &&
                    isLanguageSelected &&
                    isTypeSelected;
            })
            .sort((a, b) => {
                const titleA = transliterate(a.title.toLowerCase());
                const titleB = transliterate(b.title.toLowerCase());
                return titleA.localeCompare(titleB);
            });

        console.log('Filtered stories count:', stories.length);
        return stories;
    }, [niveauRange, durationRange, selectedLanguages, selectedTypes]);

    // Fix the renderDurationThumb function
    const renderDurationThumb = (props, state) => {
        const { key, ...restProps } = props; // Extract key from props
        return (
            <div key={key} {...restProps}>
                <div className="slider-value">{state.valueNow}</div>
            </div>
        );
    };

    const handleNiveauChange = (newRange) => {
        setNiveauRange(newRange);
    };

    const handleDurationChange = (newRange) => {
        setDurationRange(newRange);
    };

    // Helper function to get display name
    const getDisplayName = (language) => {
        // If it's one of our special languages, return the native name
        return nativeLanguageNames[language] || language;
    };

    return (
        <div className="stories-page">
            <ReducedNavbar />
            <div className="stories-content">
                <div className="stories-header">
                    <h2>{texts.storyCollection}</h2>
                </div>
                <div className="stories-sidebar">
                    <Searchbar />
                    <div className="sidebar-niveau-section">
                        <label className="niveau-label-header">{texts.level}</label>
                        <div className="niveau-labels-container">
                            {['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map((niveau, index) => (
                                <span
                                    key={niveau}
                                    className="niveau-label"
                                    style={{ left: `${(index + 1) * (100 / 6)}%` }}
                                >
                                    {niveau}
                                </span>
                            ))}
                        </div>
                        <Slider
                            value={niveauRange}
                            min={0}
                            max={6}
                            step={1}
                            onChange={handleNiveauChange}
                            className="react-slider"
                            trackClassName="track"
                            renderThumb={(props) => <div {...props} />}
                        />
                    </div>

                    <div className="sidebar-niveau-section">
                        <label className="niveau-label-header">{texts.duration}</label>
                        <Slider
                            value={durationRange}
                            min={0}
                            max={60}
                            step={1}
                            onChange={handleDurationChange}
                            className="react-slider"
                            trackClassName="track"
                            renderThumb={renderDurationThumb}
                        />
                    </div>

                    <div className="sidebar-niveau-section">
                        <label className="niveau-label-header">{texts.language}</label>
                        <div className="languages-section">
                            <div className="selected-languages">
                                {selectedLanguages.map(lang => (
                                    <span
                                        key={lang}
                                        className="language"
                                        onClick={() => handleLanguageClick(lang)}
                                    >
                                        {getDisplayName(lang)}
                                    </span>
                                ))}
                                <button
                                    ref={languageButtonRef}
                                    className="add-language"
                                    onClick={handleOpenLanguageModal}
                                >
                                    +
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="sidebar-niveau-section">
                        <label className="niveau-label-header">{texts.type}</label>
                        <div className="types-section">
                            <div className="selected-types">
                                {selectedTypes.map(type => (
                                    <span
                                        key={type}
                                        className="type"
                                        onClick={() => handleTypeClick(type)}
                                    >
                                        {type.charAt(0).toUpperCase() + type.slice(1)}
                                    </span>
                                ))}
                                <button
                                    ref={typeButtonRef}
                                    className="add-language"
                                    onClick={handleOpenTypeModal}
                                >
                                    +
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="stories-container">
                    {filteredStories.map((story) => {
                        const cardClassName = `story-card ${story.type}-card`;

                        return (
                            <div
                                key={story.id}
                                className={cardClassName}
                                onClick={() => navigate(story.path)}
                            >
                                {getContentIcon(story.type)}
                                <div className="story-info">
                                    <ReactCountryFlag
                                        countryCode={getLanguageCode(story.language)}
                                        svg
                                        style={{
                                            width: '20px',
                                            height: '15px',
                                            marginRight: '10px',
                                            borderRadius: '2px',
                                            boxShadow: '1px 1px 0px var(--shadow-color)',
                                            border: '1px solid var(--border-color)',
                                        }}
                                    />
                                    <span className="story-level">{story.level}</span>
                                    <span className="story-title">{story.title}</span>
                                    <span className="story-duration">{formatDuration(story.duration)}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <Modal
                isOpen={isLanguageModalOpen}
                onRequestClose={() => setIsLanguageModalOpen(false)}
                className="topics-modal"
                overlayClassName="niveau-modal-overlay"
                style={{
                    content: {
                        position: 'absolute',
                        top: `${languageModalPosition.top}px`,
                        left: `${languageModalPosition.left}px`,
                        transform: 'none'
                    }
                }}
            >
                <h2>{texts.languages}</h2>
                <div className="modal-items">
                    {availableLanguages.map(language => {
                        console.log('Rendering language option:', language);
                        return (
                            <span
                                key={language}
                                className={`modal-item ${selectedLanguages.includes(language) ? 'selected' : ''}`}
                                onClick={() => handleLanguageClick(language)}
                            >
                                {language}
                            </span>
                        );
                    })}
                </div>
                <button onClick={() => setIsLanguageModalOpen(false)}>{texts.confirm}</button>
            </Modal>

            <Modal
                isOpen={isTypeModalOpen}
                onRequestClose={() => setIsTypeModalOpen(false)}
                className="topics-modal"
                overlayClassName="niveau-modal-overlay"
                style={{
                    content: {
                        position: 'absolute',
                        top: `${typeModalPosition.top}px`,
                        left: `${typeModalPosition.left}px`,
                        transform: 'none',
                        zIndex: 1000
                    }
                }}
            >
                <h2>{texts.type}</h2>
                <div className="modal-items">
                    {availableTypes.map(type => (
                        <span
                            key={type}
                            className={`modal-item ${selectedTypes.includes(type) ? 'selected' : ''}`}
                            onClick={() => handleTypeClick(type)}
                        >
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                        </span>
                    ))}
                </div>
                <button onClick={() => setIsTypeModalOpen(false)}>{texts.confirm}</button>
            </Modal>
        </div>
    );
};

export default Stories;
