import React from 'react';
import './Lesson1.css';
import ReducedNavbar from '../../../Components/UI/ReducedNavbar';
import Searchbar from '../../../Components/UI/Searchbar';

const Lesson1 = () => {
    return (
        <div className="lesson-page">
            <ReducedNavbar />
            <div className="content">
                <div className="sidebar">
                    <Searchbar />
                    {/* Additional sidebar content can be added here */}
                </div>
                <div className="main-content">
                    <div className="lesson-header">
                        <h2>
                            <span style={{ fontWeight: 900 }}>Lesson 1</span> | <span>The Jansen Family</span>
                        </h2>
                    </div>
                    <div className="lesson-container">
                        {/* Your lesson content will go here */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Lesson1;
