import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import './WidgetContainer.css'; // Import the updated CSS file for styling
import VocabularyIcon from '@mui/icons-material/Translate'; // Example icon
import GrammarIcon from '@mui/icons-material/ExtensionRounded';
import StoriesIcon from '@mui/icons-material/AutoStoriesRounded';
import LessonsIcon from '@mui/icons-material/SchoolRounded';
import MediaIcon from '@mui/icons-material/PermMedia';
import { useLanguage } from '../../LanguageContext'; // Use the Language Context

const WidgetContainer = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const { language } = useLanguage(); // Get the current language from the LanguageContext

  // Widget titles based on the selected language
  const widgetTitles = {
    vocabulary: language === 'English' ? 'Vocabulary'
      : language === 'Deutsch' ? 'Vokabeln'
        : language === 'Español' ? 'Vocabulario'
          : language === 'Français' ? 'Vocabulaire'
            : language === 'Italiano' ? 'Vocabolario'
              : language === 'Português' ? 'Vocabulário'
                : 'Словарный запас',
    grammar: language === 'English' ? 'Grammar'
      : language === 'Deutsch' ? 'Grammatik'
        : language === 'Español' ? 'Gramática'
          : language === 'Français' ? 'Grammaire'
            : language === 'Italiano' ? 'Grammatica'
              : language === 'Português' ? 'Gramática'
                : 'Грамматика',
    stories: language === 'English' ? 'Stories'
      : language === 'Deutsch' ? 'Geschichten'
        : language === 'Español' ? 'Historias'
          : language === 'Français' ? 'Histoires'
            : language === 'Italiano' ? 'Storie'
              : language === 'Português' ? 'Histórias'
                : 'Истории',
    lessons: language === 'English' ? 'Lessons'
      : language === 'Deutsch' ? 'Lektionen'
        : language === 'Español' ? 'Lecciones'
          : language === 'Français' ? 'Leçons'
            : language === 'Italiano' ? 'Lezioni'
              : language === 'Português' ? 'Lições'
                : 'Уроки',
    media: language === 'English' ? 'Media'
      : language === 'Deutsch' ? 'Medien'
        : language === 'Español' ? 'Medios'
          : language === 'Français' ? 'Médias'
            : language === 'Italiano' ? 'Media'
              : language === 'Português' ? 'Mídia'
                : 'Медиа',
  };

  return (
    <div className="widget-grid">
      <div
        className="widget widget-2x4"
        id="vocabulary-widget"
        onClick={() => navigate('/vocabulary')} // Add onClick handler
      >
        <VocabularyIcon className="widget-icon" />
        <h3>{widgetTitles.vocabulary}</h3>
      </div>
      <div
        className="widget widget-2x2"
        id="grammar-widget"
        onClick={() => navigate('/grammar')} // Add onClick handler
      >
        <GrammarIcon className="widget-icon" />
        <h3>{widgetTitles.grammar}</h3>
      </div>
      <div
        className="widget widget-2x1"
        id="stories-widget"
        onClick={() => navigate('/stories')} // Add onClick handler
      >
        <StoriesIcon className="widget-icon" />
        <h3>{widgetTitles.stories}</h3>
      </div>
      <div
        className="widget widget-2x3"
        id="lessons-widget"
        onClick={() => navigate('/lessons')} // Add onClick handler
      >
        <LessonsIcon className="widget-icon" />
        <h3>{widgetTitles.lessons}</h3>
      </div>
      <div
        className="widget widget-2x2"
        id="media-widget"
        onClick={() => navigate('/media')} // Add onClick handler
      >
        <MediaIcon className="widget-icon" />
        <h3>{widgetTitles.media}</h3>
      </div>
    </div>
  );
};

export default WidgetContainer;
