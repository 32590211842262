import React, { useState } from 'react';
import './SignInField.css'; // Import the brutalist CSS
import { useLanguage } from '../../LanguageContext'; // Use the Language Context

const SignInField = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const { language } = useLanguage(); // Get the current language from the LanguageContext

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleNameChange = (e) => setName(e.target.value);
  const handleToggle = () => setIsLogin(!isLogin);
  const handleSubmit = () => {
    if (isLogin) {
      // Add login logic here
      console.log('Logging in with:', email, password);
    } else {
      // Add signup logic here
      console.log('Signing up with:', name, email, password);
    }
  };

  // Text based on the selected language
  const text = {
    login: language === 'English' ? 'Log in' : language === 'Deutsch' ? 'Anmelden' : 'Войти',
    signUp: language === 'English' ? 'Sign Up' : language === 'Deutsch' ? 'Registrieren' : 'Регистрация',
    email: language === 'English' ? 'Email' : language === 'Deutsch' ? 'E-Mail' : 'Эл. почта',
    password: language === 'English' ? 'Password' : language === 'Deutsch' ? 'Passwort' : 'Пароль',
    name: language === 'English' ? 'Name' : language === 'Deutsch' ? 'Name' : 'Имя',
    letsGo: language === 'English' ? "Let's go!" : language === 'Deutsch' ? 'Los geht\'s!' : 'Поехали!',
    confirm: language === 'English' ? 'Confirm' : language === 'Deutsch' ? 'Bestätigen' : 'Подтвердить',
  };

  return (
    <div className="wrapper">
      <div className="card-switch">
        <label className="switch">
          <input type="checkbox" className="toggle" onChange={handleToggle} />
          <span className="slider"></span>
          <span className="card-side"></span>
          <div className="flip-card__inner">
            <div className="flip-card__front">
              <div className="title">{text.login}</div>
              <form className="flip-card__form" onSubmit={(e) => e.preventDefault()}>
                <input
                  className="flip-card__input"
                  name="email"
                  placeholder={text.email}
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <input
                  className="flip-card__input"
                  name="password"
                  placeholder={text.password}
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <div className="button-container">
                  <button className="flip-card__btn" onClick={handleSubmit}>
                    {text.letsGo}
                  </button>
                  <button className="button">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" height="24" viewBox="0 0 24 24" width="24">
                      <path
                        d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                        fill="#4285F4"
                      />
                      <path
                        d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                        fill="#34A853"
                      />
                      <path
                        d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                        fill="#FBBC05"
                      />
                      <path
                        d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                        fill="#EA4335"
                      />
                      <path d="M1 1h22v22H1z" fill="none" />
                    </svg>
                  </button>
                </div>
              </form>
            </div>
            <div className="flip-card__back">
              <div className="title">{text.signUp}</div>
              <form className="flip-card__form" onSubmit={(e) => e.preventDefault()}>
                <input
                  className="flip-card__input"
                  name="name"
                  placeholder={text.name}
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                />
                <input
                  className="flip-card__input"
                  name="email"
                  placeholder={text.email}
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <input
                  className="flip-card__input"
                  name="password"
                  placeholder={text.password}
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <div className="button-container">
                  <button className="flip-card__btn" onClick={handleSubmit}>
                    {text.confirm}
                  </button>
                  <button className="button">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" height="24" viewBox="0 0 24 24" width="24">
                      <path
                        d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                        fill="#4285F4"
                      />
                      <path
                        d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                        fill="#34A853"
                      />
                      <path
                        d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                        fill="#FBBC05"
                      />
                      <path
                        d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                        fill="#EA4335"
                      />
                      <path d="M1 1h22v22H1z" fill="none" />
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </label>
      </div>
    </div>
  );
};

export default SignInField;
