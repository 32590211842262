import React from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import './ProfileButton.css'; // Import the updated CSS
import FaceIcon from '@mui/icons-material/Face'; // Retaining your existing FaceIcon

const ProfileButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/signin'); // Navigate to the login page on click
  };

  return (
    <button className="profile_button" onClick={handleClick}>
      <FaceIcon className="picon" />
    </button>
  );
};

export default ProfileButton;
