import React from 'react';
import './SignIn.css';
import SignInField from '../Components/UI/SignInField';

const Login = () => {
  return (
    <div className="login-container">
      <SignInField />
    </div>
  );
};

export default Login;
