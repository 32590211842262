import { TranslateOutlined, RuleOutlined, ArticleOutlined, BackupTableOutlined, BookOutlined, QuizOutlined } from '@mui/icons-material';
import { useLanguage } from '../LanguageContext';
import { getCommonTexts } from '../Components/LanguageCourse/LanguageCourse';

const getLessonTexts = (language) => {
    const common = getCommonTexts(language);

    return {
        courseName: language === 'English' ? 'Norwegian'
            : language === 'Deutsch' ? 'Norwegisch'
                : language === 'Español' ? 'Noruego'
                    : language === 'Français' ? 'Norvégien'
                        : language === 'Italiano' ? 'Norvegese'
                            : language === 'Português' ? 'Norueguês'
                                : 'Норвежский',
        lessons: {
            1: { classifier: `${common.lesson} 1`, title: language === 'English' ? 'Basic Greetings and Introductions' : language === 'Deutsch' ? 'Grundlegende Grüße und Vorstellungen' : language === 'Español' ? 'Saludos básicos y presentaciones' : language === 'Français' ? 'Salutations de base et présentations' : language === 'Italiano' ? 'Saluti di base e presentazioni' : language === 'Português' ? 'Saudações básicas e apresentações' : 'Базовые приветствия и знакомства' },
            2: { classifier: `${common.lesson} 2`, title: language === 'English' ? 'Numbers and Counting' : language === 'Deutsch' ? 'Zahlen und Zählen' : language === 'Español' ? 'Números y conteo' : language === 'Français' ? 'Nombres et comptage' : language === 'Italiano' ? 'Numeri e conteggio' : language === 'Português' ? 'Números e contagem' : 'Числа и счет' },
            3: { classifier: `${common.lesson} 3`, title: language === 'English' ? 'Basic Phrases' : language === 'Deutsch' ? 'Grundlegende Ausdrücke' : language === 'Español' ? 'Frases básicas' : language === 'Français' ? 'Phrases de base' : language === 'Italiano' ? 'Frasi di base' : language === 'Português' ? 'Frases básicas' : 'Основные фразы' },
            4: { classifier: `${common.lesson} 4`, title: language === 'English' ? 'Family Vocabulary' : language === 'Deutsch' ? 'Familienvokabular' : language === 'Español' ? 'Vocabulario familiar' : language === 'Français' ? 'Vocabulaire familial' : language === 'Italiano' ? 'Vocabolario familiare' : language === 'Português' ? 'Vocabulário familiar' : 'Семейная лексика' },
            5: { classifier: `${common.lesson} 5`, title: language === 'English' ? 'Days of the Week' : language === 'Deutsch' ? 'Wochentage' : language === 'Español' ? 'Días de la semana' : language === 'Français' ? 'Jours de la semaine' : language === 'Italiano' ? 'Giorni della settimana' : language === 'Português' ? 'Dias da semana' : 'Дни недели' },
            6: { classifier: `${common.lesson} 6`, title: language === 'English' ? 'Basic Verbs' : language === 'Deutsch' ? 'Grundlegende Verben' : language === 'Español' ? 'Verbos básicos' : language === 'Français' ? 'Verbes de base' : language === 'Italiano' ? 'Verbi di base' : language === 'Português' ? 'Verbos básicos' : 'Основные глаголы' },
            7: { classifier: `${common.lesson} 7`, title: language === 'English' ? 'Shopping Vocabulary' : language === 'Deutsch' ? 'Einkaufsvokabular' : language === 'Español' ? 'Vocabulario de compras' : language === 'Français' ? 'Vocabulaire d\'achat' : language === 'Italiano' ? 'Vocabolario di acquisto' : language === 'Português' ? 'Vocabulário de compras' : 'Лексика для покупок' },
            8: { classifier: `${common.lesson} 8`, title: language === 'English' ? 'Weather and Seasons' : language === 'Deutsch' ? 'Wetter und Jahreszeiten' : language === 'Español' ? 'Tiempo y estaciones' : language === 'Français' ? 'Météo et saisons' : language === 'Italiano' ? 'Meteo e stagioni' : language === 'Português' ? 'Tempo e estações' : 'Погода и времена года' },
            9: { classifier: `${common.lesson} 9`, title: language === 'English' ? 'Time and Schedules' : language === 'Deutsch' ? 'Zeit und Zeitpläne' : language === 'Español' ? 'Tiempo y horarios' : language === 'Français' ? 'Temps et horaires' : language === 'Italiano' ? 'Tempo e orari' : language === 'Português' ? 'Tempo e cronogramas' : 'Время и расписания' },
            10: { classifier: `${common.lesson} 10`, title: language === 'English' ? 'Common Adjectives' : language === 'Deutsch' ? 'Häufige Adjektive' : language === 'Español' ? 'Adjetivos comunes' : language === 'Français' ? 'Adjectifs communs' : language === 'Italiano' ? 'Aggettivi comuni' : language === 'Português' ? 'Adjetivos comuns' : 'Распространенные прилагательные' },
            11: { classifier: `${common.lesson} 11`, title: language === 'English' ? 'Talking About Hobbies' : language === 'Deutsch' ? 'Über Hobbys sprechen' : language === 'Español' ? 'Hablar sobre aficiones' : language === 'Français' ? 'Parler des loisirs' : language === 'Italiano' ? 'Parlare delle passioni' : language === 'Português' ? 'Falar sobre hobbies' : 'Разговор о хобби' },
            12: { classifier: `${common.lesson} 12`, title: language === 'English' ? 'Directions and Navigation' : language === 'Deutsch' ? 'Richtungen und Navigation' : language === 'Español' ? 'Direcciones y navegación' : language === 'Français' ? 'Directions et navigation' : language === 'Italiano' ? 'Direzioni e navigazione' : language === 'Português' ? 'Direções e navegação' : 'Направления и навигация' },
            13: { classifier: `${common.lesson} 13`, title: language === 'English' ? 'Food and Drinks' : language === 'Deutsch' ? 'Essen und Getränke' : language === 'Español' ? 'Comida y bebidas' : language === 'Français' ? 'Nourriture et boissons' : language === 'Italiano' ? 'Cibo e bevande' : language === 'Português' ? 'Comida e bebidas' : 'Еда и напитки' },
            14: { classifier: `${common.lesson} 14`, title: language === 'English' ? 'Basic Conversation' : language === 'Deutsch' ? 'Grundlegende Konversation' : language === 'Español' ? 'Conversación básica' : language === 'Français' ? 'Conversation de base' : language === 'Italiano' ? 'Conversazione di base' : language === 'Português' ? 'Conversação básica' : 'Основной разговор' },
            15: { classifier: `${common.lesson} 15`, title: language === 'English' ? 'Talking About Health' : language === 'Deutsch' ? 'Über Gesundheit sprechen' : language === 'Español' ? 'Hablar sobre salud' : language === 'Français' ? 'Parler de santé' : language === 'Italiano' ? 'Parlare della salute' : language === 'Português' ? 'Falar sobre saúde' : 'Разговор о здоровье' },
            16: { classifier: `${common.lesson} 16`, title: language === 'English' ? 'Jobs and Professions' : language === 'Deutsch' ? 'Berufe und Berufe' : language === 'Español' ? 'Trabajos y profesiones' : language === 'Français' ? 'Métiers et professions' : language === 'Italiano' ? 'Lavori e professioni' : language === 'Português' ? 'Profissões e profissões' : 'Профессии' },
            17: { classifier: `${common.lesson} 17`, title: language === 'English' ? 'Basic Grammar Structures' : language === 'Deutsch' ? 'Grundlegende Grammatikstrukturen' : language === 'Español' ? 'Estructuras gramaticales básicas' : language === 'Français' ? 'Structures grammaticales de base' : language === 'Italiano' ? 'Strutture grammaticali di base' : language === 'Português' ? 'Estruturas gramaticais básicas' : 'Основные грамматические структуры' },
            18: { classifier: `${common.lesson} 18`, title: language === 'English' ? 'Describing Places' : language === 'Deutsch' ? 'Orte beschreiben' : language === 'Español' ? 'Describir lugares' : language === 'Français' ? 'Décrire les lieux' : language === 'Italiano' ? 'Descrivere i luoghi' : language === 'Português' ? 'Descrever lugares' : 'Описание мест' }
        },
        tests: {
            1: { classifier: `${common.test} 1`, title: language === 'English' ? 'Greetings and Numbers Test' : language === 'Deutsch' ? 'Test zu Grüßen und Zahlen' : language === 'Español' ? 'Prueba de saludos y números' : language === 'Français' ? 'Test de salutations et nombres' : language === 'Italiano' ? 'Test di saluti e numeri' : language === 'Português' ? 'Teste de saudações e números' : 'Тест по приветствиям и числам' },
            2: { classifier: `${common.test} 2`, title: language === 'English' ? 'Vocabulary Test 1' : language === 'Deutsch' ? 'Vokabeltest 1' : language === 'Español' ? 'Prueba de vocabulario 1' : language === 'Français' ? 'Test de vocabulaire 1' : language === 'Italiano' ? 'Test di vocabolario 1' : language === 'Português' ? 'Teste de vocabulário 1' : 'Лексический тест 1' },
            3: { classifier: `${common.test} 3`, title: language === 'English' ? 'Grammar and Conversation Test' : language === 'Deutsch' ? 'Grammatik- und Konversationstest' : language === 'Español' ? 'Prueba de gramática y conversación' : language === 'Français' ? 'Test de grammaire et conversation' : language === 'Italiano' ? 'Test di grammatica e conversazione' : language === 'Português' ? 'Teste de gramática e conversação' : 'Тест по грамматике и разговору' }
        },
        trainers: {
            vocabulary: language === 'English' ? 'Vocabulary Trainer'
                : language === 'Deutsch' ? 'Vokabeltrainer'
                    : language === 'Español' ? 'Entrenador de Vocabulario'
                        : language === 'Français' ? 'Entraîneur de Vocabulaire'
                            : language === 'Italiano' ? 'Allenatore di Vocabolario'
                                : language === 'Português' ? 'Treinador de Vocabulário'
                                    : 'Тренажер словаря',
            grammar: language === 'English' ? 'Grammar Trainer'
                : language === 'Deutsch' ? 'Grammatiktrainer'
                    : language === 'Español' ? 'Entrenador de Gramática'
                        : language === 'Français' ? 'Entraîneur de Grammaire'
                            : language === 'Italiano' ? 'Allenatore di Grammatica'
                                : language === 'Português' ? 'Treinador de Gramática'
                                    : 'Тренажер грамматики',
            articles: language === 'English' ? 'Articles Trainer'
                : language === 'Deutsch' ? 'Artikeltrainer'
                    : language === 'Español' ? 'Entrenador de Artículos'
                        : language === 'Français' ? 'Entraîneur d\'Articles'
                            : language === 'Italiano' ? 'Allenatore di Articoli'
                                : language === 'Português' ? 'Treinador de Artigos'
                                    : 'Тренажер артиклей'
        }
    };
};

// Wrap the exports in a function that uses the language context
export const useNorwegianCourse = () => {
    const { language } = useLanguage();
    const texts = getLessonTexts(language);

    const norwegianLessons = [
        { id: 1, type: 'lesson', classifier: texts.lessons[1].classifier, title: texts.lessons[1].title, duration: '15 min', progress: 100, path: '/lessons/norwegian/lesson-1', icon: <BookOutlined className="lesson-icon" /> },
        { id: 2, type: 'lesson', classifier: texts.lessons[2].classifier, title: texts.lessons[2].title, duration: '17 min', progress: 100, path: '/lessons/norwegian/lesson-2', icon: <BookOutlined className="lesson-icon" /> },
        { id: 3, type: 'lesson', classifier: texts.lessons[3].classifier, title: texts.lessons[3].title, duration: '12 min', progress: 100, path: '/lessons/norwegian/lesson-3', icon: <BookOutlined className="lesson-icon" /> },
        { id: 4, type: 'lesson', classifier: texts.lessons[4].classifier, title: texts.lessons[4].title, duration: '15 min', progress: 100, path: '/lessons/norwegian/lesson-4', icon: <BookOutlined className="lesson-icon" /> },
        { id: 5, type: 'lesson', classifier: texts.lessons[5].classifier, title: texts.lessons[5].title, duration: '17 min', progress: 100, path: '/lessons/norwegian/lesson-5', icon: <BookOutlined className="lesson-icon" /> },
        { id: 6, type: 'lesson', classifier: texts.lessons[6].classifier, title: texts.lessons[6].title, duration: '12 min', progress: 100, path: '/lessons/norwegian/lesson-6', icon: <BookOutlined className="lesson-icon" /> },
        { id: 7, type: 'test', classifier: texts.tests[1].classifier, title: texts.tests[1].title, duration: '17 min', progress: 100, path: '/lessons/norwegian/tests-1', icon: <QuizOutlined className="test-icon" /> },
        { id: 8, type: 'lesson', classifier: texts.lessons[7].classifier, title: texts.lessons[1].title, duration: '15 min', progress: 87, path: '/lessons/norwegian/lesson-7', icon: <BookOutlined className="lesson-icon" /> },
        { id: 9, type: 'lesson', classifier: texts.lessons[8].classifier, title: texts.lessons[2].title, duration: '17 min', progress: 12, path: '/lessons/norwegian/lesson-8', icon: <BookOutlined className="lesson-icon" /> },
        { id: 10, type: 'lesson', classifier: texts.lessons[9].classifier, title: texts.lessons[3].title, duration: '12 min', progress: 0, path: '/lessons/norwegian/lesson-9', icon: <BookOutlined className="lesson-icon" /> },
        { id: 11, type: 'lesson', classifier: texts.lessons[10].classifier, title: texts.lessons[4].title, duration: '15 min', progress: 0, path: '/lessons/norwegian/lesson-10', icon: <BookOutlined className="lesson-icon" /> },
        { id: 12, type: 'lesson', classifier: texts.lessons[11].classifier, title: texts.lessons[5].title, duration: '17 min', progress: 0, path: '/lessons/norwegian/lesson-11', icon: <BookOutlined className="lesson-icon" /> },
        { id: 13, type: 'lesson', classifier: texts.lessons[12].classifier, title: texts.lessons[6].title, duration: '12 min', progress: 0, path: '/lessons/norwegian/lesson-12', icon: <BookOutlined className="lesson-icon" /> },
        { id: 14, type: 'test', classifier: texts.tests[2].classifier, title: texts.tests[1].title, duration: '17 min', progress: 0, path: '/lessons/norwegian/tests-2', icon: <QuizOutlined className="test-icon" /> },
        { id: 15, type: 'lesson', classifier: texts.lessons[13].classifier, title: texts.lessons[1].title, duration: '15 min', progress: 0, path: '/lessons/norwegian/lesson-13', icon: <BookOutlined className="lesson-icon" /> },
        { id: 16, type: 'lesson', classifier: texts.lessons[14].classifier, title: texts.lessons[2].title, duration: '17 min', progress: 0, path: '/lessons/norwegian/lesson-14', icon: <BookOutlined className="lesson-icon" /> },
        { id: 17, type: 'lesson', classifier: texts.lessons[15].classifier, title: texts.lessons[3].title, duration: '12 min', progress: 0, path: '/lessons/norwegian/lesson-15', icon: <BookOutlined className="lesson-icon" /> },
        { id: 18, type: 'lesson', classifier: texts.lessons[16].classifier, title: texts.lessons[4].title, duration: '15 min', progress: 0, path: '/lessons/norwegian/lesson-16', icon: <BookOutlined className="lesson-icon" /> },
        { id: 19, type: 'lesson', classifier: texts.lessons[17].classifier, title: texts.lessons[5].title, duration: '17 min', progress: 0, path: '/lessons/norwegian/lesson-17', icon: <BookOutlined className="lesson-icon" /> },
        { id: 20, type: 'lesson', classifier: texts.lessons[18].classifier, title: texts.lessons[6].title, duration: '12 min', progress: 0, path: '/lessons/norwegian/lesson-18', icon: <BookOutlined className="lesson-icon" /> },
        { id: 21, type: 'test', classifier: texts.tests[3].classifier, title: texts.tests[1].title, duration: '17 min', progress: 0, path: '/lessons/norwegian/tests-3', icon: <QuizOutlined className="test-icon" /> },
        // ... other lessons following the same pattern
    ];

    const norwegianTrainers = [
        {
            title: texts.trainers.vocabulary,
            path: '/norwegian/vocabulary',
            icon: <TranslateOutlined className="trainer-icon" />
        },
        {
            title: texts.trainers.grammar,
            path: '/norwegian/grammar',
            icon: <RuleOutlined className="trainer-icon" />
        },
        {
            title: texts.trainers.articles,
            path: '/norwegian/articles',
            icon: <ArticleOutlined className="trainer-icon" />
        }
    ];

    return {
        norwegianLessons,
        norwegianTrainers,
        norwegianCourseStats,
        courseName: texts.courseName
    };
};

export const norwegianCourseStats = {
    completedLessons: 47,
    totalLessons: 108,
    completedTests: 1,
    totalTests: 11,
    wordsLearned: 21,
    currentLevel: 'A1'
};
