import React from 'react';
import { useParams, Navigate } from 'react-router-dom';

// Import all stories
const storyComponents = {
    'german/die-uhr-der-verlorenen-zeit': React.lazy(() => import('./germanStories/die-uhr-der-verlorenen-zeit')),
    'norwegian/eirik-og-forbannelsens-lenker': React.lazy(() => import('./norwegianStories/eirik-og-forbannelsens-lenker.js')),
    // Add more stories here
};

const StoryPage = () => {
    const { language, storySlug } = useParams();
    const storyPath = `${language}/${storySlug}`;

    const StoryComponent = storyComponents[storyPath];

    if (!StoryComponent) {
        return <Navigate to="/stories" replace />;
    }

    return (
        <React.Suspense fallback={<div>Loading...</div>}>
            <StoryComponent />
        </React.Suspense>
    );
};

export default StoryPage; 