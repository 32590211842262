import React from 'react';
import LanguageCourse from '../../Components/LanguageCourse/LanguageCourse';
import { useGermanCourse } from '../../data/germanLessons';

const GermanLessons = () => {
    const { germanLessons, germanTrainers, germanCourseStats, courseName } = useGermanCourse();

    return (
        <LanguageCourse
            countryCode="DE"
            languageName={courseName}
            lessons={germanLessons}
            courseStats={germanCourseStats}
            trainers={germanTrainers}
        />
    );
};

export default GermanLessons;
