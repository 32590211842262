import React from 'react';
import LanguageCourse from '../../Components/LanguageCourse/LanguageCourse';
import { useNorwegianCourse } from '../../data/norwegianLessons';

const NorwegianLessons = () => {
    const { norwegianLessons, norwegianTrainers, norwegianCourseStats, courseName } = useNorwegianCourse();

    return (
        <LanguageCourse
            countryCode="NO"
            languageName={courseName}
            lessons={norwegianLessons}
            courseStats={norwegianCourseStats}
            trainers={norwegianTrainers}
        />
    );
};

export default NorwegianLessons;
