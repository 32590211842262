import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import SignIn from './Pages/SignIn';
import InfinyVocab from './Pages/InfinyVocab';
import Lessons from './Pages/Lessons';
import { ThemeProvider } from './ThemeContext'; // Import ThemeProvider
import { LanguageProvider } from './LanguageContext'; // Import the Language Provider
import './App.css';
import VocabCreator from './Pages/VocabCreator';
import GermanLessons from './Pages/Lessons/GermanLessons';
import RussianLessons from './Pages/Lessons/RussianLessons';
import EnglishLessons from './Pages/Lessons/EnglishLessons';
import NorwegianLessons from './Pages/Lessons/NorwegianLessons';
import NorwegianLesson1 from './Pages/Lessons/NorwegianLessons/Lesson1';
import Stories from './Pages/Stories';
import StoryPage from './Pages/Stories/StoryPage';
import StoryConverter from './Pages/Stories/StoryConverter';

const App = () => {
  return (  
    <ThemeProvider>
      <LanguageProvider>
        <Router>
          <div>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/SignIn" element={<SignIn />} />
              <Route path="/vocabulary" element={<InfinyVocab />} />
              <Route path="/lessons" element={<Lessons />} />
              <Route path="/vocabulary/create" element={<VocabCreator />} />
              <Route path="/lessons/german" element={<GermanLessons />} />
              <Route path="/lessons/russian" element={<RussianLessons />} />
              <Route path="/lessons/english" element={<EnglishLessons />} />
              <Route path="/lessons/norwegian" element={<NorwegianLessons />} />
              <Route path="/lessons/norwegian/lesson-1" element={<NorwegianLesson1 />} />
              <Route path="/stories" element={<Stories />} />
              <Route path="/stories/:language/:storySlug" element={<StoryPage />} />
              <Route path="/stories/converter" element={<StoryConverter />} />
            </Routes>
          </div>
        </Router>
      </LanguageProvider>
    </ThemeProvider>
  );
};

export default App;
